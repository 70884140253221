/****************************************************************************************************
 * Logging Service
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 ****************************************************************************************************/

const LoggingService = {};

LoggingService.log = function (message, extras = null) {
    console.log(message);
};

LoggingService.warn = function (message, extras = null) {
    console.warn(message);
};

export default LoggingService;
