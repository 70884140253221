/****************************************************************************************************
 * Image MJML module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 27 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const Model = {
    name: 'image',
    version: 1,
    // MJML //////////
    tagName: 'mj-section',
    attributes: {
        'css-class': 'ClEtbModule', // Same for all modules.
        'padding': '25px 25px 25px 25px',
        'background-color': '#ffffff',
        'border': 'none',
        'border-radius': '0'
    },
    children: [
        {
            tagName: 'mj-column',
            attributes: {
                'padding': '0 0 0 0'
            },
            children: [
                {
                    tagName: 'mj-image',
                    attributes: {
                        'align': 'center',
                        // alt
                        // border
                        // border-radius
                        // container-background-color
                        // css-class
                        // fluid-on-mobile
                        // height
                        'href': '',
                        'padding': '0',
                        // padding-bottom
                        // padding-left
                        // padding-right
                        // padding-top
                        // rel
                        'src': '#',
                        // srcset
                        'target': '_blank',
                        // title
                        // usemap // See https://www.w3schools.com/html/html_images_imagemap.asp
                        'width': '200px'
                    }
                }
            ]
        }
    ]
};

export default {
    getRaw () {
        return cloneDeep(Model);
    },
    getName () {
        return Model.name;
    }
};
