/****************************************************************************************************
 * Navigation Bar MJML module.
 *
 * See https://mjml.io/documentation/#mj-navbar
 *
 * @author Dimitris Gkoulis
 * @createdAt 29 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const Model = {
    name: 'navbar',
    version: 1,
    // MJML //////////
    tagName: 'mj-section',
    attributes: {
        'css-class': 'ClEtbModule', // Same for all modules.
        'padding': '0',
        'background-color': 'none',
        'border': 'none',
        'border-radius': '0'
    },
    children: [
        {
            tagName: 'mj-column',
            attributes: {
                'padding': '0 0 0 0'
            },
            children: [
                {
                    tagName: 'mj-navbar',
                    attributes: {
                        'align': 'center'
                        // base-url
                        // css-class
                        // hamburger
                        // ico-align
                        // ico-close
                        // ico-color
                        // ico-font-family
                        // ico-font-size
                        // ico-line-height
                        // ico-open
                        // ico-padding
                        // ico-padding-bottom
                        // ico-padding-left
                        // ico-padding-right
                        // ico-padding-top
                        // ico-text-decoration
                        // ico-text-transform
                    },
                    children: []
                }
            ]
        }
    ]
};

export default {
    getRaw () {
        return cloneDeep(Model);
    },
    getName () {
        return Model.name;
    }
};
