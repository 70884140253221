<template>
    <div class="EmailTemplateCodeEditor">
        <div class="d-flex flex-row">
            <div v-bind:style="columnAStyle">
                <div class="UI-Fullscreen-Column for-view bg-white border-right">
                    <div class="UI-Fullscreen-Column__Container">
                        <div class="UI-Fullscreen-Column__Body">
                            <codemirror v-model="html" :options="codeMirrorOptions"></codemirror>
                        </div>
                    </div>
                </div>
            </div>
            <div v-bind:style="columnBStyle">
                <div class="UI-Fullscreen-Column for-view">
                    <div class="UI-Fullscreen-Column__Container">
                        <div class="UI-Fullscreen-Column__Body">
                            <clv-iframe-sync :html="html"></clv-iframe-sync>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { codemirror } from 'vue-codemirror';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/addon/selection/active-line.js';
import 'codemirror/addon/edit/closetag.js';

/**
 * EmailTemplate code editor.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2 November 2020
 */
export default {
    name: 'EmailTemplateCodeEditor',
    components: {
        codemirror
    },
    props: {
        htmlInitial: {
            type: String,
            required: false,
            default: null
        }
    },
    data () {
        return {
            html: '',

            // Code editor (CodeMirror)
            codeEditorSize: 'normal',
            codeMirrorOptions: {
                mode: 'text/html',
                // theme: 'ambiance',
                indentUnit: 4,
                tabSize: 4,
                direction: 'ltr',
                lineWrapping: true,
                lineNumbers: false, // faster when is false. @future Find a way to add line number without affecting performance.
                line: true,
                styleActiveLine: true,
                autoCloseTags: true,
                viewportMargin: Infinity
            }
        };
    },
    computed: {
        columnAStyle () {
            return {
                'width': '50%'
            };
        },
        columnBStyle () {
            return {
                'width': '50%'
            };
        }
    },
    mounted () {
        // Lock scroll (UI issue when iframe is switch from empty state to non-empty state ie builder has module).
        document.body.classList.add('overflow-hidden'); // Ugly but what can I do?

        if (typeof this.htmlInitial === 'string') {
            this.html = this.htmlInitial;
        } else {
            this.html = '';
        }

        // Send initial.
        // This is necessary because this component may pre-process the provided HTML code.
        // Ugly block of code but what can I do?
        this.$emit('build-success-initial', {
            builderData: null,
            html: this.html,
            error: null,
            timestamp: Date.now()
        });

        this.$watch('html', {
            handler: function (value) {
                const timestamp = Date.now();
                this.$emit('build-success', {
                    builderData: null,
                    html: value,
                    error: null,
                    timestamp: timestamp
                });
            }
        });
    },
    beforeDestroy () {
        document.body.classList.remove('overflow-hidden'); // Ugly but what can I do?
    }
};
</script>

<style lang="scss">
    .EmailTemplateCodeEditor {

        .CodeMirror {
            height: auto !important;
        }
    }
</style>
