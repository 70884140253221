<template>
    <div class="EtbModuleCustomizerGroup">
        <div class="el-form-group">
            <label :for="builderModulePartFieldDeclaration.name">{{ builderModulePartFieldDeclaration.title }}</label>
            <div class="shadow-sm border rounded-sm p-3 mb-3"
                 v-for="(dataValueItem, dataValueItemIndex) in dataValue"
                 :key="dataValueItem.rKey">
                <!-- HEADER -->
                <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-0 h6"
                       v-b-toggle="dataValueItem.rKey">
                        {{ getListingItemName(dataValueItem, dataValueItemIndex) }}
                    </p>
                    <p class="mb-0 small text-muted cursor-pointer"
                       @click="removeItem(dataValueItemIndex)">
                        <i class="far fa-trash-alt"></i>
                    </p>
                </div>

                <!-- BODY -->
                <b-collapse :id="dataValueItem.rKey"
                            class="mt-3"
                            :accordion="'etb-module-customizer-group-accordion_' + builderModulePartFieldDeclaration.name"
                            role="tabpanel">
                    <etb-module-customizer-control class="mb-3"
                                                   v-for="field in builderModulePartFieldDeclaration.children"
                                                   :key="field.name"
                                                   :builder-module-part-field-declaration="field"
                                                   :builder-module-data-data-value="dataValueItem[field.name]"
                                                   v-on:change="handleEtbModuleCustomizerControlChange($event, dataValueItemIndex)"></etb-module-customizer-control>
                </b-collapse>
            </div>
            <button class="btn btn-block btn-primary" @click="addItem">{{ $t('Add item') }}</button>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import RandomUtils from '@/common/utils/random.utils';
import EtbModuleCustomizerControl from './EtbModuleCustomizerControl';

const BUILDER_MODULE_DATA_DATA_WATCHER_DEBOUNCE = 100;

/**
 * Module Customizer Control Group.
 *
 * @author Dimitris Gkoulis
 * @createdAt 29 October 2020
 * @lastModifiedAt 9 November 2020 (translations)
 */
export default {
    name: 'EtbModuleCustomizerControlGroup',
    components: {
        EtbModuleCustomizerControl
    },
    props: {
        builderModulePartFieldDeclaration: {
            type: Object,
            default: null,
            required: true
        },
        builderModuleDataDataValue: {
            type: Array, // IT MUST BE ARRAY!
            required: true
        }
    },
    data () {
        return {
            dataValue: []
        };
    },
    beforeMount () {
        this.dataValue = cloneDeep(this.builderModuleDataDataValue);
        for (const item of this.dataValue) {
            item.rKey = RandomUtils.getUniqueId();
        }

        // Listen to changes on 'builderModuleDataData'.
        // On each change send the whole builderModuleData with the new 'data' field.
        this.$watch('dataValue', {
            handler: debounce(function (ignoredValue) {
                this.$emit('change', {
                    name: this.builderModulePartFieldDeclaration.name,
                    builderModuleDataDataValue: cloneDeep(this.dataValue.map(function (item) {
                        const clone = cloneDeep(item);
                        delete clone.rKey;
                        return clone;
                    }))
                });
            }, BUILDER_MODULE_DATA_DATA_WATCHER_DEBOUNCE),
            deep: true,
            immediate: false
        });
    },
    methods: {
        /**
         * Handles EtbModuleCustomizerControl's signal for change.
         */
        handleEtbModuleCustomizerControlChange ($event, index) {
            if (typeof $event !== 'object') return;
            if (typeof $event.name !== 'string') return;
            if (!$event.hasOwnProperty('builderModuleDataDataValue')) return;
            if (typeof index !== 'number') return;
            if (index < 0) return;
            if (index >= this.dataValue.length) return;
            this.$set(this.dataValue[index], $event.name, $event.builderModuleDataDataValue);
        },

        /**
         * Remove the item in "index".
         */
        removeItem (index) {
            if (typeof index !== 'number') return;
            if (index < 0) return;
            if (index >= this.dataValue.length) return;
            this.dataValue.splice(index, 1);
        },

        /**
         * Add a new item.
         *
         * This operation is considered unsafe as front-end is not being aware of business logic.
         * However, for now it's convenient and reduces the complexity of calling a remote API.
         */
        addItem () {
            const item = {};
            item.rKey = RandomUtils.getUniqueId();
            for (const field of this.builderModulePartFieldDeclaration.children) {
                if (field.type === 'group') continue;
                item[field.name] = field.defaultValue;
            }
            this.dataValue.push(item);
        },

        /**
         * A helper function that returns the name of the list entry (if exists).
         */
        getListingItemName (value, index) {
            if (typeof this.builderModulePartFieldDeclaration.uiOptions.toStringField === 'string') {
                const name = value[this.builderModulePartFieldDeclaration.uiOptions.toStringField];
                if (typeof name === 'string') return name;
                else return this.$t('Item #') + index;
            } else {
                return this.$t('Item #') + index;
            }
        }
    }
};
</script>
