/****************************************************************************************************
 * Text MJML module.
 *
 * See https://mjml.io/documentation/#mj-text
 *
 * @author Dimitris Gkoulis
 * @createdAt 27 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const Model = {
    name: 'text',
    version: 1,
    // MJML //////////
    tagName: 'mj-section',
    attributes: {
        'css-class': 'ClEtbModule', // Same for all modules.
        'padding': '25px 25px 25px 25px',
        'background-color': '#ffffff',
        'background-size': '120%',
        'background-repeat': 'repeat',
        'border': 'none',
        'border-radius': '0'
    },
    children: [
        {
            tagName: 'mj-column',
            attributes: {
                'padding': '0 0 0 0'
            },
            children: [
                {
                    tagName: 'mj-text',
                    attributes: {
                        'color': '#000000',
                        // font-family
                        'font-size': '16px',
                        // font-style
                        'font-weight': 'normal',
                        'line-height': '1.5',
                        // letter-spacing
                        // height
                        // text-decoration
                        // text-transform
                        'align': 'left',
                        // container-background-color
                        // padding // Default is '10px 25px'
                        'padding': '0 0 0 0'
                        // padding-top
                        // padding-bottom
                        // padding-left
                        // padding-right
                        // css-class
                    },
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tristique, libero eget fermentum congue, sapien arcu suscipit tortor, sed pretium elit nisi luctus lacus. Nunc leo eros, posuere sed porttitor in, eleifend id mi. Nullam at mauris hendrerit, efficitur ligula non, lacinia quam. Vestibulum a mollis sapien.'
                }
            ]
        }
    ]
};

export default {
    getRaw () {
        return cloneDeep(Model);
    },
    getName () {
        return Model.name;
    }
};
