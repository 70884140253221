<template>
    <div class="EtbModuleCustomizerControlFileImage">
        <!-- ERROR STATE 2020-07-19 -->
        <simple-state-error class="mb-4"
                            v-if="storedObjectError"
                            :error="storedObjectError"></simple-state-error>

        <!-- IMAGE PREVIEW -->
        <div class="position-relative w-100 mb-2" v-if="dataValue">
            <!--suppress HtmlUnknownTarget -->
            <el-image :src="dataValue">
                <div slot="error">
                    <div class="el-image__error">
                        <p class="mb-1 text-danger small font-weight-bold">{{ $t('Could not retrieve image') }}</p>
                    </div>
                </div>
            </el-image>
        </div>

        <!-- IMAGE CONTROLS -->
        <div class="position-relative w-100">
            <ul class="nav nav-pills nav-fill HorizontalNavPills HorizontalNavPills--Bordered border-top border-bottom mb-2">
                <li class="nav-item">
                    <p class="mb-0 nav-link cursor-pointer"
                       :class="{ 'active': uploadActiveRoute === 'upload' }"
                       @click="uploadActiveRoute = 'upload'">{{ $t('Upload') }}</p>
                </li>
                <li class="nav-item">
                    <p class="mb-0 nav-link cursor-pointer"
                       :class="{ 'active': uploadActiveRoute === 'directUrl' }"
                       @click="uploadActiveRoute = 'directUrl'">{{ $t('Direct URL') }}</p>
                </li>
                <li class="nav-item">
                    <p class="mb-0 nav-link cursor-pointer"
                       :class="{ 'active': uploadActiveRoute === 'browse' }"
                       @click="uploadActiveRoute = 'browse'">{{ $t('Browse Images') }}</p>
                </li>
            </ul>
            <div v-if="uploadActiveRoute === 'upload'">
                <form enctype="multipart/form-data" novalidate>
                    <div class="EtbModuleCustomizerControlFileImage__Dropbox">
                        <input type="file"
                               :name="uploadFieldName"
                               :disabled="storedObjectUploading"
                               @change="filesChange($event.target.name, $event.target.files)"
                               accept="image/*"
                               class="EtbModuleCustomizerControlFileImage__Dropbox__InputFile">
                        <div class="EtbModuleCustomizerControlFileImage__Dropbox__Cosmetics">
                            <element-loading :active="true" background-color="transparent" v-if="storedObjectUploading"></element-loading>
                            <p class="mb-0 small" v-else>{{ $t('Choose a file or drag it here') }}</p>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else-if="uploadActiveRoute === 'directUrl'">
                <div class="el-form-group">
                    <el-input type="url"
                              v-model="dataValue"
                              @change="onDirectUrlChange"></el-input>
                </div>
            </div>
            <div v-else-if="uploadActiveRoute === 'browse'">
                <div class="border p-2 text-center small">
                    <p class="mb-0">{{ $t('Available soon') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { StorageStoredObjectService } from '@/common/services/api.service';

/**
 * Module Customizer Control for Image File.
 *
 * @author Dimitris Gkoulis
 * @createdAt 27 October 2020
 * @lastModifiedAt 9 November 2020 (translations)
 */
export default {
    name: 'EtbModuleCustomizerControlFileImage',
    props: {
        dataValueProvided: {
            type: String,
            default: null,
            required: false
        }
    },
    data () {
        return {
            dataValue: null,
            uploadFieldName: 'file',
            uploadActiveRoute: 'upload',

            storedObjectUploading: false,
            storedObject: null, // Upload result
            storedObjectError: null // Upload error
        };
    },
    beforeMount () {
        this.dataValue = cloneDeep(this.dataValueProvided);
    },
    methods: {
        resetDataValue () {
            this.dataValue = null;
            this.$emit('change', null);
        },
        onDirectUrlChange () {
            this.$emit('change', this.dataValue);
        },
        filesChange (fieldName, fileList) {
            if (!fileList.length) return;
            if (fileList.length > 1) return; // One file per upload. Multiple files upload is not supported!

            const file = fileList[0];

            this.dataValue = null;
            this.storedObjectUploading = true;
            this.storedObject = null;
            this.storedObjectError = null;

            StorageStoredObjectService.uploadContentStoredObject(file)
                .then(({ data }) => {
                    this.storedObject = data;
                    this.dataValue = data.url;
                    this.$emit('change', this.dataValue);
                })
                .catch((reason) => {
                    this.storedObjectError = reason;
                })
                .finally(() => {
                    this.storedObjectUploading = false;
                });
        }
    }
};
</script>

<style lang="scss">
    $checkerboard-pattern-color: #dcdcdc; // #808080.

    .EtbModuleCustomizerControlFileImage {

        .el-image {
            width: 100%;
            min-height: 200px; // REQUIRED TO STOP FLASHING WHEN USER EDITING THE URL.
            // Necessary to display transparent images.
            background-image: linear-gradient(45deg, $checkerboard-pattern-color 25%, transparent 25%),
                linear-gradient(-45deg, $checkerboard-pattern-color 25%, transparent 25%),
                linear-gradient(45deg, transparent 75%, $checkerboard-pattern-color 75%),
                linear-gradient(-45deg, transparent 75%, $checkerboard-pattern-color 75%);
            background-size: 20px 20px;
            background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        // A FIX.
        .el-image > div {
            position: relative;
            width: 100%;
        }
        .el-image__error {
            width: 100%;
            height: 200px;
            flex-direction: column;
        }
    }
    .EtbModuleCustomizerControlFileImage__Dropbox {
        position: relative;
        background-color: transparent;
        outline: 2px dashed #dcdcdc;
        outline-offset: -3px;
        min-height: 60px;
        cursor: pointer;
        transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        p {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .EtbModuleCustomizerControlFileImage__Dropbox:hover {
        background-color: #f4f4f4;
    }
    .EtbModuleCustomizerControlFileImage__Dropbox__InputFile {
        opacity: 0; /* invisible but it's there! */
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 20;
        cursor: pointer;
    }
    .EtbModuleCustomizerControlFileImage__Dropbox__Cosmetics {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        text-align: center;

        p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
</style>
