/****************************************************************************************************
 * Builder Module repository.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 7 November 2020 (categories)
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

import UrlService from '@/common/services/url.service';

import BuilderModuleDeclarationModel from '../builder-declaration/builder-module-declaration.model';
import BuilderModulePartDeclarationModel from '../builder-declaration/builder-module-part-declaration.model';
import BuilderModulePartFieldDeclarationModel from '../builder-declaration/builder-module-part-field-declaration.model';
import MjmlComponentHelper from '../mjml/mjml-component-helper';

import Helper, {
    SETTINGS_WIDTH,
    SETTINGS_MIN_WIDTH,
    SETTINGS_MAX_WIDTH,

    getCommonSectionDeclaration,
    getCommonSectionImageDeclaration,
    getCommonColumnDeclaration,
    getCommonImageDeclaration,
    getCommonImageLinkDeclaration,
    getCommonNavbarLinksDeclaration,
    getCommonNavbarLinksDefaultValue
} from './builder-module-declaration-repository-helper';

const LOGO_DISPLAY_ORDER = 100;
const NAVBAR_DISPLAY_ORDER = 100;
const NAVBAR_LOGO_DISPLAY_ORDER = 100;
const IMAGE_DISPLAY_ORDER = 101;
const TEXT_DISPLAY_ORDER = 102;
const BUTTON_DISPLAY_ORDER = 103;
const SPACING_DISPLAY_ORDER = 104;

const CATEGORIES_DISPLAY_ORDER = {
    'General': 0,
    'Header': 1,
    'Menu': 2,
    'Content': 3,
    'Call to Action': 4,
    'Footer': 5
};

const Settings = BuilderModuleDeclarationModel.construct('settings', 'settings', 1, 'Settings', ['Settings'], null, 1, [
    BuilderModulePartDeclarationModel.construct('settings', 'Settings', false, [
        BuilderModulePartFieldDeclarationModel.getSpacing('bodyTopBottomSpacing', null, 'Spacing', 'topbottom', { top: 20, right: 0, bottom: 20, left: 0, x: 0, y: 0 }),
        BuilderModulePartFieldDeclarationModel.getColor('bodyBackgroundColor', null, 'Background', '#f4f4f4'),
        BuilderModulePartFieldDeclarationModel.getSelect('bodyFont', null, 'Font', 'Roboto', MjmlComponentHelper.getFontListAsOptions()),
        BuilderModulePartFieldDeclarationModel.getPixels('bodyWidth', null, 'Width', SETTINGS_WIDTH, SETTINGS_MIN_WIDTH, SETTINGS_MAX_WIDTH, 10)
    ])
]);

const buttonPreviewUrl = UrlService.urlForWebAppBasePublic('/img/email-template/dummy-button-basic-01.png');
const Button = BuilderModuleDeclarationModel.construct('button', 'button', 1, 'Button', ['General', 'Call to action'], buttonPreviewUrl, BUTTON_DISPLAY_ORDER, [
    BuilderModulePartDeclarationModel.construct('buttonContent', 'Button Content', false, [
        BuilderModulePartFieldDeclarationModel.getText('buttonContent', ['children', 0, 'children', 0, 'content'], 'Content', 'Button Text'),
        BuilderModulePartFieldDeclarationModel.getTextLink('buttonLink', ['children', 0, 'children', 0, 'attributes', 'href'], 'Link', '#')
    ]),
    BuilderModulePartDeclarationModel.construct('buttonStyle', 'Button Style', false, [
        BuilderModulePartFieldDeclarationModel.getColor('buttonBackgroundColor', ['children', 0, 'children', 0, 'attributes', 'background-color'], 'Background Color', '#14103d'),
        BuilderModulePartFieldDeclarationModel.getColor('buttonColor', ['children', 0, 'children', 0, 'attributes', 'color'], 'Text Color', '#ffffff'),
        BuilderModulePartFieldDeclarationModel.getSelect('buttonAlignment', ['children', 0, 'children', 0, 'attributes', 'align'], 'Alignment', 'center', Helper.getAlignmentOptions()),
        BuilderModulePartFieldDeclarationModel.getBorder('buttonBorder', ['children', 0, 'children', 0, 'attributes', 'border'], 'Border'),
        BuilderModulePartFieldDeclarationModel.getPixels('buttonBorderRadius', ['children', 0, 'children', 0, 'attributes', 'border-radius'], 'Border Radius', 3, 0, 100),
        BuilderModulePartFieldDeclarationModel.getPixels('buttonFontSize', ['children', 0, 'children', 0, 'attributes', 'font-size'], 'Text Size', 14, 8, 36),
        BuilderModulePartFieldDeclarationModel.getSelect('buttonFontWeight', ['children', 0, 'children', 0, 'attributes', 'font-weight'], 'Text Weight', 'bold', Helper.getFontWeightOptions()),
        BuilderModulePartFieldDeclarationModel.getSpacing('buttonInnerPadding', ['children', 0, 'children', 0, 'attributes', 'inner-padding'], 'Button Spacing', 'xy', { x: 26, y: 15 }, 0, parseInt((SETTINGS_MAX_WIDTH / 2).toFixed(0)))
    ]),
    getCommonSectionDeclaration(),
    getCommonSectionImageDeclaration()
]);

const imagePreviewUrl = UrlService.urlForWebAppBasePublic('/img/email-template/dummy-image-basic-01.png');
const Image = BuilderModuleDeclarationModel.construct('image', 'image', 1, 'Image', ['General', 'Content'], imagePreviewUrl, IMAGE_DISPLAY_ORDER, [
    getCommonImageDeclaration(0, Helper.getSampleImage01(), SETTINGS_WIDTH, 'center'),
    getCommonImageLinkDeclaration(),
    getCommonSectionDeclaration('#ffffff', { top: 0, right: 0, bottom: 0, left: 0 }),
    getCommonSectionImageDeclaration()
]);

const logoPreviewUrl = UrlService.urlForWebAppBasePublic('/img/email-template/dummy-logo-basic-01.png');
const Logo = BuilderModuleDeclarationModel.construct('logo', 'image', 1, 'Logo', ['General', 'Header', 'Footer'], logoPreviewUrl, LOGO_DISPLAY_ORDER, [
    getCommonImageDeclaration(0, Helper.getSampleLogo01(), 200, 'center'),
    getCommonImageLinkDeclaration(0),
    getCommonSectionDeclaration('#14103D', { top: 25, right: 25, bottom: 25, left: 25 }),
    getCommonSectionImageDeclaration()
]);

const navbarPreviewUrl = UrlService.urlForWebAppBasePublic('/img/email-template/dummy-navbar-01.png');
const Navbar = BuilderModuleDeclarationModel.construct('navbar', 'navbar', 1, 'Navigation Bar', ['Header', 'Menu'], navbarPreviewUrl, NAVBAR_DISPLAY_ORDER, [
    BuilderModulePartDeclarationModel.construct('navbar', 'Navigation Bar', false, [
        BuilderModulePartFieldDeclarationModel.getSelect('navbarAlignment', ['children', 0, 'children', 0, 'attributes', 'align'], 'Alignment', 'center', Helper.getAlignmentOptions()),
        getCommonNavbarLinksDeclaration(0, getCommonNavbarLinksDefaultValue(['Home', 'About', 'Contact']))
    ]),
    getCommonSectionDeclaration('#14103D', { top: 25, right: 25, bottom: 25, left: 25 }),
    getCommonSectionImageDeclaration(0)
]);

const navbarLogoPreviewUrl = UrlService.urlForWebAppBasePublic('/img/email-template/dummy-navbar-logo-01.png');
const NavbarLogo = BuilderModuleDeclarationModel.construct('navbarLogo', 'navbarLogo', 1, 'Navigation Bar with Logo', ['Header', 'Menu'], navbarLogoPreviewUrl, NAVBAR_LOGO_DISPLAY_ORDER, [
    getCommonImageDeclaration(0, Helper.getSampleLogo01(), 200, 'center'),
    getCommonImageLinkDeclaration(0),
    BuilderModulePartDeclarationModel.construct('navbar', 'Navigation Bar', false, [
        BuilderModulePartFieldDeclarationModel.getSelect('navbarAlignment', ['children', 1, 'children', 0, 'attributes', 'align'], 'Alignment', 'center', Helper.getAlignmentOptions()),
        getCommonNavbarLinksDeclaration(1, getCommonNavbarLinksDefaultValue(['Home', 'About', 'Contact']))
    ]),
    getCommonSectionDeclaration('#14103D', { top: 25, right: 25, bottom: 25, left: 25 }),
    getCommonSectionImageDeclaration(),
    getCommonColumnDeclaration(0, 30),
    getCommonColumnDeclaration(1, 70)
]);

const spacingPreviewUrl = UrlService.urlForWebAppBasePublic('/img/email-template/dummy-spacing-basic-02.png');
const Spacing = BuilderModuleDeclarationModel.construct('spacing', 'spacing', 1, 'Spacing', ['General'], spacingPreviewUrl, SPACING_DISPLAY_ORDER, [
    BuilderModulePartDeclarationModel.construct('spacing', 'Spacing', false, [
        BuilderModulePartFieldDeclarationModel.getColor('sectionBackgroundColor', ['attributes', 'background-color'], 'Background', null),
        BuilderModulePartFieldDeclarationModel.getPixels('spacerHeight', ['children', 0, 'children', 0, 'attributes', 'height'], 'Height', 20, 10, 400)
    ]),
    getCommonSectionImageDeclaration()
]);

const textPreviewUrl = UrlService.urlForWebAppBasePublic('/img/email-template/dummy-text-basic-01.png');
const Text = BuilderModuleDeclarationModel.construct('text', 'text', 1, 'Text', ['General', 'Content'], textPreviewUrl, TEXT_DISPLAY_ORDER, [
    BuilderModulePartDeclarationModel.construct('content', 'Content', false, [
        BuilderModulePartFieldDeclarationModel.getRichText('textContent', ['children', 0, 'children', 0, 'content'], 'Content', Helper.getLoremIpsumText())
    ]),
    BuilderModulePartDeclarationModel.construct('textStyle', 'Text Style', false, [
        BuilderModulePartFieldDeclarationModel.getColor('textColor', ['children', 0, 'children', 0, 'attributes', 'color'], 'Text Color', '#000000'),
        BuilderModulePartFieldDeclarationModel.getSelect('textAlignment', ['children', 0, 'children', 0, 'attributes', 'align'], 'Alignment', 'left', Helper.getAlignmentOptionsWithJustify()),
        BuilderModulePartFieldDeclarationModel.getPixels('textFontSize', ['children', 0, 'children', 0, 'attributes', 'font-size'], 'Text Size', 16, 8, 36),
        BuilderModulePartFieldDeclarationModel.getSelect('textFontWeight', ['children', 0, 'children', 0, 'attributes', 'font-weight'], 'Text Weight', 'normal', Helper.getFontWeightOptions()),
        BuilderModulePartFieldDeclarationModel.getSelect('textLineHeight', ['children', 0, 'children', 0, 'attributes', 'line-height'], 'Text Line Height', '1.5', Helper.getLineHeightOptions())
    ]),
    getCommonSectionDeclaration(),
    getCommonSectionImageDeclaration()
]);

const BuilderDeclarationMap = {
    builderSettingsDeclaration: Settings,
    builderModuleDeclarationMap: {
        [Button.name]: Button,
        [Image.name]: Image,
        [Logo.name]: Logo,
        [Navbar.name]: Navbar,
        [NavbarLogo.name]: NavbarLogo,
        [Spacing.name]: Spacing,
        [Text.name]: Text
    }
};

export default {
    /**
     * Used by the front-end clients (listing views, search, etc).
     */
    getBuilderDeclarationsByCategoryList () {
        const builderModuleDeclarationMap = cloneDeep(BuilderDeclarationMap.builderModuleDeclarationMap);
        const builderModuleDeclarationListByCategoryObject = Object.values(builderModuleDeclarationMap)
            .map(function (item) {
                return {
                    name: item.name,
                    title: item.title,
                    categories: item.categories,
                    previewImageUrl: item.previewImageUrl,
                    displayOrder: item.displayOrder
                };
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            })
            .reduce(function (accumulator, current) {
                for (const category of current.categories) {
                    if (!accumulator.hasOwnProperty(category)) {
                        let displayOrder = 100;
                        if (CATEGORIES_DISPLAY_ORDER.hasOwnProperty(category)) {
                            displayOrder = CATEGORIES_DISPLAY_ORDER[category];
                        }
                        if (typeof displayOrder !== 'number') {
                            displayOrder = 100;
                        }

                        accumulator[category] = {
                            title: category,
                            displayOrder: displayOrder,
                            rKey: 'cat-' + new Date().getTime() + '-' + (Math.floor(Math.random() * (100000 - 1)) + 1),
                            children: []
                        };
                    }
                    accumulator[category].children.push(current);
                }
                return accumulator;
            }, {});
        const builderModuleDeclarationListByCategoryList = Object.values(builderModuleDeclarationListByCategoryObject)
            .map(function (item) {
                item.children = item.children
                    .sort(function (a, b) {
                        return a.displayOrder - b.displayOrder;
                    });
                return item;
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            });
        return cloneDeep(builderModuleDeclarationListByCategoryList);
    },

    /**
     * Return a deep clone of BuilderDeclarationMap (Settings & module declaration).
     */
    getBuilderDeclarationMap () {
        return cloneDeep(BuilderDeclarationMap);
    },

    /**
     * Return a deep clone of BuilderSettingsDeclaration.
     */
    getBuilderSettingsDeclaration () {
        return cloneDeep(BuilderDeclarationMap.builderSettingsDeclaration);
    },

    /**
     * Return a deep clone of all BuilderModuleDeclaration as a map.
     */
    /*
    getBuilderModuleDeclarationMap () {
        return cloneDeep(BuilderDeclarationMap.builderModuleDeclarationMap);
    },
    */

    /**
     * Get the "name" BuilderModuleDeclaration or null if does not exist.
     */
    getBuilderModuleDeclarationByName (name) {
        if (typeof name !== 'string') return null;
        if (!BuilderDeclarationMap.builderModuleDeclarationMap.hasOwnProperty(name)) return null;
        return cloneDeep(BuilderDeclarationMap.builderModuleDeclarationMap[name]);
    },

    /**
     * Check if the "name" BuilderModuleDeclaration exists.
     */
    doesBuilderModuleDeclarationExists (name) {
        if (typeof name !== 'string') return false;
        return BuilderDeclarationMap.builderModuleDeclarationMap.hasOwnProperty(name);
    }
};
