/****************************************************************************************************
 * Spacing MJML module.
 *
 * See https://mjml.io/documentation/#mj-spacer
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 27 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const Model = {
    name: 'spacing',
    version: 1,
    // MJML //////////
    tagName: 'mj-section',
    attributes: {
        'css-class': 'ClEtbModule', // Same for all modules.
        'padding': '0',
        'background-color': 'none',
        'border': 'none',
        'border-radius': '0'
    },
    children: [
        {
            tagName: 'mj-column',
            attributes: {
                'padding': '0 0 0 0'
            },
            children: [
                {
                    tagName: 'mj-spacer',
                    attributes: {
                        'height': '10px'
                    }
                }
            ]
        }
    ]
};

export default {
    getRaw () {
        return cloneDeep(Model);
    },
    getName () {
        return Model.name;
    }
};
