<template>
    <div class="EmailTemplateBuilder" v-if="siInitialized">
        <div class="d-flex flex-row">
            <div v-bind:style="columnAStyle">
                <keep-alive :include="['EtbModuleList']">
                    <!--
                    EtbModuleCustomizer (re-mount on every identifier change - forced by changing the key)
                    :position
                    :builder-module-data
                    :builder-module-declaration
                    v-on:close
                    v-on:change

                    EtbModuleList (applied keep-alive - cached - the key remains the same)
                    :builder-module-declaration-list
                    v-on:add-new-module
                    -->
                    <component :is="activeComponentColumnA"
                               :key="activeComponentColumnAKey"
                               :position="'left'"
                               :builder-module-data="activeInstances.builderModuleData"
                               :builder-module-declaration="activeInstances.builderModuleDeclaration"
                               :builder-module-declarations-by-category-list="builderModuleDeclarationsByCategoryList"
                               v-on:add-new-module="handleEtbModuleListAddNewModule"
                               v-on:close="handleEtbModuleCustomizerClose"
                               v-on:change="handleEtbModuleCustomizerChange"></component>
                </keep-alive>
            </div>
            <div class="flex-grow-1" v-bind:style="columnBStyle">
                <div class="UI-Fullscreen-Column for-view">
                    <div class="UI-Fullscreen-Column__Container">
                        <div id="ClEmtPreviewFrameWrapper" class="UI-Fullscreen-Column__Body">
                            <button id="ClEmtSettingsButton"
                                    class="btn btn-secondary-alt"
                                    @click="siSettingsOpen = true"
                                    v-if="siSettingsOpen === false">{{ $t('Settings') }}</button>
                            <div v-if="displayEmptyState" key="email-template-empty-state">
                                <div class="EmailTemplateBuilder-EmptyState">
                                    <div class="mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M4.759 5.753h-.013v.958c-.035 1.614 4.405 1.618 4.351 0v-.957c-.129-1.528-4.226-1.536-4.338-.001zm3.545.147c0 .314-.614.571-1.37.571-.755 0-1.37-.256-1.37-.571s.615-.57 1.37-.57c.756 0 1.37.256 1.37.57zm-8.304.179l.009.005-.009-.019 11.5-6.065 11.5 6.142v5.231l-11 5.798v-5.311l9.864-5.19-10.367-5.517-10.331 5.454 9.834 5.229v5.331l-11-5.858v-5.23zm23 6.434v5.813l-11 5.674v-5.689l11-5.798zm-13.692-3.37c-.035 1.615 4.406 1.618 4.351 0v-.957c-.129-1.528-4.225-1.536-4.337-.001h-.014v.958zm2.188-1.381c.755 0 1.37.255 1.37.57 0 .314-.615.57-1.37.57s-1.37-.255-1.37-.57c0-.315.615-.57 1.37-.57zm2.162-3.354v-.956c-.13-1.527-4.225-1.535-4.337-.001h-.013v.957c-.036 1.615 4.406 1.618 4.35 0zm-2.161-1.381c.754 0 1.37.256 1.37.571 0 .314-.616.571-1.37.571-.756 0-1.37-.257-1.37-.571 0-.314.614-.571 1.37-.571zm6.712 3.684v-.957c-.13-1.528-4.226-1.536-4.336-.001h-.014v.958c-.037 1.615 4.405 1.618 4.35 0zm-3.532-.81c0-.314.615-.57 1.37-.57.756 0 1.371.256 1.371.57s-.615.57-1.371.57c-.755 0-1.37-.256-1.37-.57zm-3.677 12.408v5.691l-11-5.673v-5.875l11 5.857z"/></svg>
                                    </div>
                                    <div>
                                        <p class="mb-0" v-html="$t('start_by_adding_blocks_v1')"></p>
                                    </div>
                                </div>
                            </div>
                            <div v-else key="email-template-preview-frame">
                                <!--suppress HtmlUnknownTarget -->
                                <iframe :src="null" id="ClEmtPreviewFrame"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-bind:style="columnCStyle" v-if="siSettingsOpen">
                <etb-module-customizer :key="activeComponentColumnCKey"
                                       :position="'right'"
                                       :builder-module-data="builderData.settings"
                                       :builder-module-declaration="builderSettingsDeclaration"
                                       v-on:close="handleEtbModuleCustomizerCloseForSettings"
                                       v-on:change="handleEtbModuleCustomizerChangeForSettings"></etb-module-customizer>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import RandomUtils from '@/common/utils/random.utils';
import ArrayUtils from '@/common/utils/array.utils';
import LoggingService from '@/common/services/logging.service';
import UrlService from '@/common/services/url.service';
import EmailTemplateBuilderApi from '@/modules/EmailTemplateBuilderBackend/email-template-builder.api';
import EtbModuleCustomizer from './EtbModuleCustomizer';
import EtbModuleList from './EtbModuleList';

const PREVIEW_WRAPPER_ELEMENT_ID = 'ClEmtPreviewFrameWrapper';
const PREVIEW_ELEMENT_ID = 'ClEmtPreviewFrame';
const PREVIEW_MODULE_CLASS = 'ClEtbModule';
const PREVIEW_MODULE_IDENTIFYING_CLASS_PREFIX = 'ClEtbModule__';
const PREVIEW_ACTIVE_MODULE_CLASS = 'ClEtbModule--Editable--Active';

function refreshFrameWrapperBackgroundColor (backgroundColor = '#ffffff', elementId = PREVIEW_WRAPPER_ELEMENT_ID) {
    if (typeof backgroundColor !== 'string') backgroundColor = '#ffffff';
    const element = document.getElementById(elementId);
    if (element === null || element === undefined) return;
    element.style.backgroundColor = backgroundColor;
}

function refreshFrameHtml (html, elementId = PREVIEW_ELEMENT_ID) {
    const frame = document.getElementById(elementId);
    if (frame === null || frame === undefined) return;

    let frameDocument;
    if (frame.contentWindow) frameDocument = frame.contentWindow.document;
    else frameDocument = frame.document;

    frameDocument.open();
    frameDocument.writeln(html);
    frameDocument.close();
}

function refreshFrameHeight (elementId = PREVIEW_ELEMENT_ID) {
    const frame = document.getElementById(elementId);
    if (frame === null || frame === undefined) return;

    let frameDocument;
    if (frame.contentWindow) frameDocument = frame.contentWindow.document;
    else frameDocument = frame.document;

    frame.style.height = frameDocument.body.scrollHeight + 'px';
}

/**
 * Appends the necessary scripts and stylesheets to the generated HTML code inside the preview iframe.
 */
function addCompulsoryScriptsAndStylesheetsToPreviewFrame (elementId = PREVIEW_ELEMENT_ID) {
    let etbScriptMain = document.createElement('script');
    etbScriptMain.src = UrlService.urlForWebAppBasePublic('/libs/etb/main.js');
    etbScriptMain.type = 'text/javascript';

    let etbScriptResizeListener = document.createElement('script');
    etbScriptResizeListener.src = UrlService.urlForWebAppBasePublic('/libs/etb/resize-listener.js');
    etbScriptResizeListener.type = 'text/javascript';

    let etbStylesheetMain = document.createElement('link');
    etbStylesheetMain.href = UrlService.urlForWebAppBasePublic('/libs/etb/main.css');
    etbStylesheetMain.type = 'text/css';
    etbStylesheetMain.rel = 'stylesheet';

    let frame = document.getElementById(elementId);
    if (frame === null || frame === undefined) return;

    let frameDocument;
    if (frame.contentWindow) frameDocument = frame.contentWindow.document;
    else frameDocument = frame.document;

    frameDocument.head.appendChild(etbStylesheetMain);
    frameDocument.body.appendChild(etbScriptMain);
    frameDocument.body.appendChild(etbScriptResizeListener);
}

/**
 * Appends the sorting functionality scripts and stylesheets to the generated HTML code inside the preview iframe.
 */
function addSortScriptsAndStylesheetsToPreviewFrame (elementId = PREVIEW_ELEMENT_ID) {
    let etbScriptDraggableSortable = document.createElement('script');
    etbScriptDraggableSortable.src = UrlService.urlForWebAppBasePublic('/libs/etb/draggable.sortable.min.js');
    etbScriptDraggableSortable.type = 'text/javascript';

    let etbScriptSort = document.createElement('script');
    etbScriptSort.src = UrlService.urlForWebAppBasePublic('/libs/etb/sort.js');
    etbScriptSort.type = 'text/javascript';

    let etbStylesheetSort = document.createElement('link');
    etbStylesheetSort.href = UrlService.urlForWebAppBasePublic('/libs/etb/sort.css');
    etbStylesheetSort.type = 'text/css';
    etbStylesheetSort.rel = 'stylesheet';

    let frame = document.getElementById(elementId);
    if (frame === null || frame === undefined) return;

    let frameDocument;
    if (frame.contentWindow) frameDocument = frame.contentWindow.document;
    else frameDocument = frame.document;

    frameDocument.head.appendChild(etbStylesheetSort);
    frameDocument.body.appendChild(etbScriptDraggableSortable);
    frameDocument.body.appendChild(etbScriptSort);
}

/**
 * Removes active class from all modules and adds active class to active module if any.
 */
function refreshActiveModuleStyleToPreviewFrame (newActiveModuleElementId = null, elementId = PREVIEW_ELEMENT_ID) {
    if (typeof elementId !== 'string') return;
    if (elementId.trim() === '') return;

    // Make sure it's string or null and not undefined or anything else.
    if (typeof newActiveModuleElementId !== 'string') newActiveModuleElementId = null;

    let frame = document.getElementById(elementId);
    if (frame === null || frame === undefined) return;

    let frameDocument;
    if (frame.contentWindow) frameDocument = frame.contentWindow.document;
    else frameDocument = frame.document;

    // Remove active module class from all modules.
    const elementList = frameDocument.getElementsByClassName(PREVIEW_MODULE_CLASS);
    for (const elementItem of elementList) {
        // just add '_none_' to force comparison fail... Ugly but what can I do?
        const targetClass = PREVIEW_MODULE_IDENTIFYING_CLASS_PREFIX +
            (typeof newActiveModuleElementId === 'string' ? newActiveModuleElementId : '_none_');
        if (elementItem.id === newActiveModuleElementId || elementItem.classList.contains(targetClass)) {
            elementItem.classList.add(PREVIEW_ACTIVE_MODULE_CLASS);
        } else {
            elementItem.classList.remove(PREVIEW_ACTIVE_MODULE_CLASS);
        }
    }
}

/**
 * Email Template builder.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 10 November 2020
 */
export default {
    name: 'EmailTemplateBuilder',
    components: {
        EtbModuleCustomizer
    },
    props: {
        /**
         * Initial builder data.
         *
         * After initialization any changes
         * from parent component on this object will be ignored!
         */
        builderDataInitial: {
            type: Object,
            default: null,
            required: false
        }
    },
    data () {
        return {
            /**
             * A state indicator that tell if process has had run at least once.
             */
            siInitialized: false,

            /**
             * true while HTML is being built.
             */
            siBuilding: false,

            /**
             * true while a new module is being added.
             */
            siAddingModule: false,

            /**
             * true when settings customizer is open.
             */
            siSettingsOpen: false,

            /**
             * Builder settings declaration.
             * The structure is exactly the same as BuilderModuleDeclaration.
             */
            builderSettingsDeclaration: {},

            /**
             * BuilderModuleDeclaration instances as map (fetched from back-end).
             */
            builderModuleDeclarationMap: {},

            /**
             * Builder Module Declaration lists by category for listing component.
             */
            builderModuleDeclarationsByCategoryList: [],

            /**
             * Builder data (ie user input).
             */
            builderData: null,

            /**
             * Active instances.
             *
             * They must be synchronized to each other.
             * Watcher takes care of synchronization.
             */
            activeInstances: {
                identifier: null,
                builderModuleData: null,
                builderModuleDeclaration: null
            },

            /**
             * Active component in column 'A'.
             */
            activeComponentColumnA: EtbModuleList,

            /**
             * Active component key in column 'A'.
             */
            activeComponentColumnAKey: 'EtbModuleList',

            /**
             * Active component key in column 'C'.
             */
            activeComponentColumnCKey: RandomUtils.getUniqueId()
        };
    },
    computed: {
        displayEmptyState () {
            return this.builderData.moduleDataList.length === 0;
        },
        columnAStyle () {
            if (this.activeInstances.identifier === null) {
                return {
                    'width': '20%',
                    'max-width': '280px',
                    'min-width': '160px'
                };
            }
            return {
                'width': '20%',
                'min-width': '416px'
            };
        },
        columnBStyle () {
            return {}; // flex grow 1.
        },
        columnCStyle () {
            return {
                'width': this.siSettingsOpen === true ? '15%' : '0%',
                'max-width': '320px',
                'min-width': '265px'
            };
        }
    },
    async mounted () {
        // Lock scroll (UI issue when iframe is switch from empty state to non-empty state ie builder has module).
        document.body.classList.add('overflow-hidden'); // Ugly but what can I do?

        this.siInitialized = false;

        // Get and initialize the builder declaration information.
        await EmailTemplateBuilderApi.getBuilderDeclarationMap()
            .then((data) => {
                this.builderSettingsDeclaration = data.builderSettingsDeclaration;
                this.builderModuleDeclarationMap = data.builderModuleDeclarationMap;
            })
            .catch((reason) => {
                LoggingService.warn(reason);
                // It's very unlikely to fail...
                this.this.builderSettingsDeclaration = {};
                this.builderModuleDeclarationMap = {};
            });

        // Get and initialize the builder declaration information for the UI.
        await EmailTemplateBuilderApi.getBuilderModuleDeclarationsByCategoryList()
            .then((data) => {
                this.builderModuleDeclarationsByCategoryList = data;
            })
            .catch((reason) => {
                LoggingService.warn(reason);
                // It's very unlikely to fail...
                this.this.builderModuleDeclarationsByCategoryList = [];
            });

        // Clone the data provided by the parent component.
        this.builderData = cloneDeep(this.builderDataInitial);
        if (this.builderData === null || this.builderData === undefined) {
            await EmailTemplateBuilderApi.constructBuilderData()
                .then((data) => {
                    this.builderData = cloneDeep(data);
                })
                .catch((reason) => LoggingService.warn(reason));
        }

        // @future WE MUST CHECK IF BUILDERDATA IS VALID. IF NOT, WE MUST SEND TO PARENT SIGNAL TO SET NULL!

        // Set true before HTML building (in order to make the iframe visible).
        this.siInitialized = true;

        // Establish communication between component (this window) and iframe.
        // The 1st (commented code) is preferred.
        // Until you make sure that it does not intercept other message handlers user the 2nd block.
        /* window.onmessage = function (e) {
            this.handleFrameMessage(e);
        }.bind(this); */
        window.addEventListener('message', function (e) {
            this.handleFrameMessage(e);
        }.bind(this));

        // Run for the first time and notify parent.
        await this.buildHtml(true)
            .then(() => void 0)
            .catch((reason) => LoggingService.warn(reason));

        // Then listen to changes on 'builderData'.
        this.$watch('builderData', {
            handler: function (ignoredValue) {
                this.buildHtml(false)
                    .then(() => void 0)
                    .catch((reason) => LoggingService.warn(reason));
            },
            deep: true,
            immediate: false
        });

        // Listen to changes on 'activeInstances.identifier'.
        // On each change make sure all related data are synchronized and valid.
        this.$watch('activeInstances.identifier', {
            handler: function (value) {
                if (value === null || value === undefined) {
                    this.activeInstances.identifier = null;
                    this.activeInstances.builderModuleData = null;
                    this.activeInstances.builderModuleDeclaration = null;
                    this.activeComponentColumnA = EtbModuleList;
                    this.activeComponentColumnAKey = 'EtbModuleList';

                    this.ensureActiveModuleStyle();
                } else {
                    // Find active ModuleData.
                    let builderModuleData = null; // @future use find.
                    for (const builderModuleDataItem of this.builderData.moduleDataList) {
                        if (builderModuleDataItem.key === value) {
                            builderModuleData = builderModuleDataItem;
                        }
                    }

                    // Check if moduleData was found.
                    if (builderModuleData === null || builderModuleData === undefined) {
                        // @future Report error?
                        // This change will trigger watcher again (reset).
                        this.activeInstances.identifier = null;
                        return;
                    }

                    // Based on active ModuleData find active BuilderModuleDeclaration.
                    // noinspection JSUnresolvedVariable
                    const builderModuleDeclaration = this.builderModuleDeclarationMap[builderModuleData.name];

                    // Check if BuilderModuleDeclaration was found.
                    if (builderModuleDeclaration === null || builderModuleDeclaration === undefined) {
                        // @future Report error?
                        // This change will trigger watcher again (reset).
                        this.activeInstances.identifier = null;
                        return;
                    }

                    // Set active instances (BE CAREFUL - DEEP CLONE).
                    this.activeInstances.builderModuleData = cloneDeep(builderModuleData);
                    this.activeInstances.builderModuleDeclaration = cloneDeep(builderModuleDeclaration);
                    this.activeComponentColumnA = EtbModuleCustomizer;
                    this.activeComponentColumnAKey = 'EtbModuleCustomizer_' + new Date().getTime();

                    this.ensureActiveModuleStyle();
                }
            },
            immediate: false
        });
    },
    beforeDestroy () {
        document.body.classList.remove('overflow-hidden'); // Ugly but what can I do?
    },
    methods: {
        /**
         * Executes the full process (async ops, HTML building, signals to parent, etc).
         */
        buildHtml (initial = false) {
            this.siBuilding = true;
            const timestamp = Date.now();

            return EmailTemplateBuilderApi.transformBuilderDataToHtml(this.builderData)
                .then((result) => {
                    const bodyBackgroundColor = get(this.builderData, 'settings.data.bodyBackgroundColor', '#ffffff');
                    refreshFrameWrapperBackgroundColor(bodyBackgroundColor, PREVIEW_WRAPPER_ELEMENT_ID);

                    // Refresh iframe content (without timeouts - we use async communication).
                    refreshFrameHtml(result.html, PREVIEW_ELEMENT_ID);

                    // Now add the necessary scripts and stylesheets
                    // in order to enable editing and parent-iframe communication.
                    addCompulsoryScriptsAndStylesheetsToPreviewFrame(PREVIEW_ELEMENT_ID);
                    addSortScriptsAndStylesheetsToPreviewFrame(PREVIEW_ELEMENT_ID);

                    // Make sure that active module has the right style.
                    this.ensureActiveModuleStyle();

                    const signalName = 'build-success' + (initial === true ? '-initial' : '');
                    this.$emit(signalName, {
                        builderData: this.builderData,
                        html: result.html,
                        error: null,
                        timestamp: timestamp
                    });

                    return Promise.resolve(result);
                })
                .catch((reason) => {
                    const signalName = 'build-fail' + (initial === true ? '-initial' : '');
                    this.$emit(signalName, {
                        builderData: this.builderData,
                        html: '',
                        error: reason,
                        timestamp: timestamp
                    });

                    return Promise.reject(reason);
                })
                .finally(() => {
                    this.siBuilding = false;
                });
        },

        /**
         * Ensures active module style consistency.
         *
         * It's not enough to run only when selecting a module to edit.
         * It's necessary to run in a few other situations (e.g. when order changes, etc)
         * ie when HTML is re-written in preview iframe.
         */
        ensureActiveModuleStyle () {
            if (typeof this.activeInstances.identifier === 'string') {
                refreshActiveModuleStyleToPreviewFrame(this.activeInstances.identifier, PREVIEW_ELEMENT_ID);
            } else {
                refreshActiveModuleStyleToPreviewFrame(null, PREVIEW_ELEMENT_ID);
            }
        },

        /**
         * Handles message sent by the iframe.
         */
        handleFrameMessage (message) {
            if (message === null || message === undefined) return;
            const data = message.data;
            if (data === null || data === undefined) return;

            // Decide action.
            if (data === 'resize') {
                refreshFrameHeight(PREVIEW_ELEMENT_ID);
                return;
            }

            if (typeof data === 'object') {
                const action = data.action;
                const identifier = data.identifier;
                // if identifier === 'settings' then index will be -1. (@future do not run if identifier === 'settings').
                const index = this.builderData.moduleDataList.findIndex(function (item) {
                    return item.key === identifier;
                });

                switch (action) {
                case 'reload':
                    // @future ATTENTION (7 Nov 2020): Causes serious synchronization problems.
                    // It called multiple times and affects the UX.
                    // Use a counter. Listen to a counter...? One-unified method...? But, it still seems wrong...
                    /*
                    setTimeout(function () {
                        this.buildHtml(false)
                            .then(() => void 0)
                            .catch((reason) => LoggingService.warn(reason));
                    }.bind(this), PREVIEW_FRAME_RELOAD_TIMEOUT);
                    */
                    break;
                case 'edit':
                    // IMPORTANT: This is selection. Not the opposite. So the identifier must always be valid.
                    // If user wants to *unselect* module through iframe another module message should be implemented.
                    if (typeof identifier !== 'string') return;
                    if (index < 0) return;
                    // Do not reset identifier (set null as we do want to preserve the active module - if any).
                    this.activeInstances.identifier = identifier;
                    break;
                case 'moveUp':
                    if (index < 0) return;
                    if (this.builderData.moduleDataList.length === 0) return;
                    if (this.builderData.moduleDataList.length === 1) return;
                    if (index === 0) return; // Check if it's first.
                    const newIndexUp = index - 1;
                    this.builderData.moduleDataList = ArrayUtils.move(this.builderData.moduleDataList, index, newIndexUp);
                    break;
                case 'moveDown':
                    if (index < 0) return;
                    if (this.builderData.moduleDataList.length === 0) return;
                    if (this.builderData.moduleDataList.length === 1) return;
                    if (index === this.builderData.moduleDataList.length - 1) return; // Check if it's last.
                    const newIndexDown = index + 1;
                    this.builderData.moduleDataList = ArrayUtils.move(this.builderData.moduleDataList, index, newIndexDown);
                    break;
                case 'remove':
                    if (index < 0) return;
                    if (this.builderData.moduleDataList.length === 0) return;
                    this.builderData.moduleDataList.splice(index, 1);
                    // Do not forget to close customizer of module if it's active(because it won't close by its own...).
                    if (this.activeInstances.identifier === identifier) {
                        this.activeInstances.identifier = null;
                    }
                    break;
                default:
                    break;
                }
            }
        },

        /**
         * Handles new module signal from child component 'EtbModuleList.vue'.
         */
        handleEtbModuleListAddNewModule (name) {
            this.siAddingModule = true;

            if (typeof name !== 'string') return;
            if (name.trim() === '') return;
            EmailTemplateBuilderApi.constructBuilderModuleDataByName(name)
                .then((data) => {
                    this.builderData.moduleDataList.push(data);
                })
                .catch((reason) => LoggingService.warn(reason))
                .finally(() => {
                    this.siAddingModule = false;
                });
        },

        /**
         * Handles EtbModuleCustomizer's signal for change.
         *
         * It's important to use the builderModuleData provided by EtbModuleCustomizer
         * and NOT use the active identifiers!
         */
        handleEtbModuleCustomizerChange (builderModuleData) {
            const index = this.builderData.moduleDataList.findIndex(function (item) {
                return item.key === builderModuleData.key;
            });
            if (index < 0) return; // Not found.
            this.$set(this.builderData.moduleDataList, index, builderModuleData);
        },

        /**
         * Handles EtbModuleCustomizer's signal for close.
         */
        handleEtbModuleCustomizerClose () {
            this.activeInstances.identifier = null;
        },

        /**
         * Handles EtbModuleCustomizer's signal for change - SETTINGS.
         */
        handleEtbModuleCustomizerChangeForSettings (builderModuleData) {
            this.$set(this.builderData, 'settings', builderModuleData);
        },

        /**
         * Handles EtbModuleCustomizer's signal for close - SETTINGS.
         */
        handleEtbModuleCustomizerCloseForSettings () {
            this.siSettingsOpen = false;
            this.activeComponentColumnCKey = RandomUtils.getUniqueId();
        }
    }
};
</script>

<!--suppress CssInvalidPropertyValue -->
<style lang="scss">
    .EmailTemplateBuilder {
        position: relative;

        // Custom style for all labels.
        .el-form-group {

            label {
                display: block;
                user-select: none;
                cursor: pointer;
                text-transform: uppercase;
                font-size: 11px;
                line-height: 1.3;
                font-weight: 700;
                color: #727c80;
                letter-spacing: 1px;
                margin-bottom: 0.9em;
            }
        }
    }
    .EmailTemplateBuilder-EmptyState {
        width: 50%;
        max-width: 600px;
        min-width: 400px;
        border-radius: 8px;
        background-color: #fff;
        text-align: center;
        padding: 100px 0;
        margin: 80px auto 0 auto;

        svg {
            fill: #42474c;
        }
        p {
            font-weight: 600;
            font-size: 0.9em;
            line-height: 1.4;
            background-color: #42474c;
            color: transparent;
            text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
        }
    }

    iframe#ClEmtPreviewFrame {
        display: block;
        position: relative;
        width: 100%;
        border: 0;
    }

    #ClEmtSettingsButton {
        position: fixed;
        bottom: 20px;
        right: 30px;
        z-index: 100;
    }
</style>
