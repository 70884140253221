<template>
    <div class="EtbModuleCustomizer UI-Fullscreen-Column for-view bg-white"
         :class="{ 'border-right': position === 'left', 'border-left': position === 'right' }">
        <div class="UI-Fullscreen-Column__Container">
            <div class="UI-Fullscreen-Column__Header border-bottom">
                <div class="UI-Fullscreen-Column__Wrapper">
                    <div class="d-flex justify-content-between align-items-center">
                        <button class="btn btn-light--bordered"
                                :class="{ 'order-1': position === 'left', 'order-2': position === 'right' }"
                                :disabled="builderModuleDataDataNotChanged"
                                @click="onReset">{{ $t('common_action_reset') }}</button>
                        <button class="btn btn-primary"
                                :class="{ 'order-1': position === 'right', 'order-2': position === 'left' }"
                                @click="onClose">{{ $t('common_action_okay') }}</button>
                    </div>
                </div>
            </div>
            <div class="UI-Fullscreen-Column__Body" style="overflow-x: hidden">
                <div class="border-bottom"
                     v-for="(part, index) in builderModuleDeclaration.parts"
                     :key="part.name">
                    <div class="EtbModuleCustomizer__Part__Header p-4"
                         :class="{ 'EtbModuleCustomizer__Part__Header__Title--Disabled': builderModuleDeclarationHasOnePart }"
                         v-b-toggle="part.name"
                         :disabled="builderModuleDeclarationHasOnePart">
                        <p class="mb-0 EtbModuleCustomizer__Part__Header__Title">{{ $t(part.title) }}</p>
                    </div>
                    <b-collapse :id="part.name"
                                :visible="index === 0"
                                :accordion="'etb-module-customizer-accordion_' + builderModuleDeclaration.name"
                                role="tabpanel">
                        <!-- key is necessary in order to force re-mount on all controls -->
                        <div class="p-4" :key="reactivityKey">
                            <div class="mb-3"
                                 v-for="field in part.fields"
                                 :key="field.name">
                                <etb-module-customizer-control-group :builder-module-part-field-declaration="field"
                                                                     :builder-module-data-data-value="builderModuleDataData[field.name]"
                                                                     v-on:change="handleEtbModuleCustomizerControlGroupChange"
                                                                     v-if="field.type === 'group'"
                                                                     key="group"></etb-module-customizer-control-group>
                                <etb-module-customizer-control :builder-module-part-field-declaration="field"
                                                               :builder-module-data-data-value="builderModuleDataData[field.name]"
                                                               v-on:change="handleEtbModuleCustomizerControlChange"
                                                               v-else
                                                               key="normal"></etb-module-customizer-control>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import EtbModuleCustomizerControl from './EtbModuleCustomizerControl';
import EtbModuleCustomizerControlGroup from './EtbModuleCustomizerControlGroup';
import RandomUtils from '@/common/utils/random.utils';

const BUILDER_MODULE_DATA_DATA_WATCHER_DEBOUNCE = 100;

/**
 * Module Customizer
 *
 * Features:
 * - Parts are being displayed in accordion. Only one part can be opened at a time.
 * - Reset (reset to latest save)
 * - If nothing is changed, reset is disabled.
 * - If ModuleData contains only on part, display this one and disable accordion.
 * - Add styling and set buttons position based on the position of the column.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 9 November 2020 (translations)
 */
export default {
    name: 'EtbModuleCustomizer',
    components: {
        EtbModuleCustomizerControl,
        EtbModuleCustomizerControlGroup
    },
    props: {
        position: {
            type: String,
            default: 'left',
            required: false
        },
        builderModuleData: {
            type: Object,
            required: true
        },
        builderModuleDeclaration: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            reactivityKey: null,
            builderModuleDataData: {}
        };
    },
    computed: {
        builderModuleDeclarationHasOnePart () {
            return get(this.builderModuleDeclaration, 'parts', []).length === 1;
        },
        builderModuleDataDataNotChanged () {
            return isEqual(this.builderModuleDataData, get(this.builderModuleData, 'data', null));
        }
    },
    beforeMount () {
        this.resetBuilderModuleDataData();

        // Listen to changes on 'builderModuleDataData'.
        // On each change send the whole builderModuleData with the new 'data' field.
        this.$watch('builderModuleDataData', {
            handler: debounce(function (ignoredValue) {
                const builderModuleDataClone = cloneDeep(this.builderModuleData);
                builderModuleDataClone.data = cloneDeep(this.builderModuleDataData);
                this.$emit('change', builderModuleDataClone);
            }, BUILDER_MODULE_DATA_DATA_WATCHER_DEBOUNCE),
            deep: true,
            immediate: false
        });
    },
    methods: {
        resetBuilderModuleDataData () {
            this.builderModuleDataData = cloneDeep(this.builderModuleData.data);
            this.reactivityKey = RandomUtils.getUniqueId();
        },

        // Handlers //////////

        /**
         * Handles EtbModuleCustomizerControlGroup's signal for change.
         */
        handleEtbModuleCustomizerControlGroupChange ($event) {
            if (typeof $event !== 'object') return;
            if (typeof $event.name !== 'string') return;
            if (!$event.hasOwnProperty('builderModuleDataDataValue')) return;
            this.builderModuleDataData[$event.name] = $event.builderModuleDataDataValue;
        },

        /**
         * Handles EtbModuleCustomizerControl's signal for change.
         */
        handleEtbModuleCustomizerControlChange ($event) {
            if (typeof $event !== 'object') return;
            if (typeof $event.name !== 'string') return;
            if (!$event.hasOwnProperty('builderModuleDataDataValue')) return;
            this.builderModuleDataData[$event.name] = $event.builderModuleDataDataValue;
        },

        // UI Controls //////////
        /**
         * On reset we assume that user wants to continue editing the module.
         * That's why we don't send signal to parent.
         */
        onReset () {
            this.resetBuilderModuleDataData();
            // @future Remount all customizer controls?
            // E.g. is required for File Image if we want to force the change of image.
            // Give watcher time to emit changes... Ugly but what can I do...?
            /* setTimeout(function () {
                this.$emit('close');
            }.bind(this), (BUILDER_MODULE_DATA_DATA_WATCHER_DEBOUNCE + 100)); */
        },
        onClose () {
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss">
    .EtbModuleCustomizer {

        .UI-Fullscreen-Column__Body {
            overflow-y: scroll;
            overflow-x: hidden;
            background-color: #f1f1f1;
        }

        .not-collapsed,
        .show {
            background-color: #ffffff !important;
        }
    }

    .EtbModuleCustomizer__Part__Header__Title {
        text-transform: uppercase;
        line-height: 1.2;
        font-size: 12px;
        font-weight: 700;
        color: #212529;
        user-select: none;
    }
    .EtbModuleCustomizer__Part__Header__Title--Disabled {
        cursor: default;
    }
    .EtbModuleCustomizer__Part__Header:active,
    .EtbModuleCustomizer__Part__Header:focus,
    .EtbModuleCustomizer__Part__Header__Title:active,
    .EtbModuleCustomizer__Part__Header__Title:focus {
        outline: none;
    }
</style>
