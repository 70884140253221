/****************************************************************************************************
 * BuilderData model.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

import BuilderModuleDataModel from './builder-module-data.model';

const Model = {
    /**
     * Key-value pair global/shared settings.
     *
     * Settings is actually a module but it's not treated as one.
     *
     * Some of them used for structural basic MJML components
     * while some others are used as input to several custom MJML modules.
     */
    settings: BuilderModuleDataModel.getRaw(),

    /**
     * List of module data.
     */
    moduleDataList: []
};

export default {
    getRaw () {
        return cloneDeep(Model);
    }
};
