/****************************************************************************************************
 * EmailTemplateBuilder API. Simulates -future- NUtilities!
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 7 November 2020
 ****************************************************************************************************/

import { NUtilitiesService } from '@/common/services/api.service';

import EmailTemplateBuilderService from './email-template-builder.service';

const EmailTemplateBuilderApi = {};

/**
 * Get the BuilderModuleDeclaration map.
 */
EmailTemplateBuilderApi.getBuilderDeclarationMap = async function () {
    return Promise.resolve(EmailTemplateBuilderService.getBuilderDeclarationMap());
};

/**
 * Get the list that contains BuilderModuleDeclaration instances grouped by category.
 */
EmailTemplateBuilderApi.getBuilderModuleDeclarationsByCategoryList = async function () {
    return Promise.resolve(EmailTemplateBuilderService.getBuilderModuleDeclarationsByCategoryList());
};

/**
 * Constructs BuilderData with default values.
 */
EmailTemplateBuilderApi.constructBuilderData = function () {
    return Promise.resolve(EmailTemplateBuilderService.constructBuilderData());
};

/**
 * Construct BuilderModuleData for then "name" BuilderModuleDeclaration.
 */
EmailTemplateBuilderApi.constructBuilderModuleDataByName = async function (name) {
    if (typeof name !== 'string' || name.trim() === '') return Promise.reject(new Error('name is missing!'));
    const builderModuleData = EmailTemplateBuilderService.constructBuilderModuleDataByName(name);
    if (builderModuleData === null) return Promise.reject(new Error('BuilderModuleDeclaration does not exist'));
    return Promise.resolve(builderModuleData);
};

/**
 * Transforms builderData to HTML.
 */
EmailTemplateBuilderApi.transformBuilderDataToHtml = async function (builderData) {
    if (builderData === null) return Promise.reject(new Error('builderData is missing'));
    const mjmlJsonObject = EmailTemplateBuilderService.transformBuilderDataToMjmlJsonObject(builderData);
    if (mjmlJsonObject === null) return Promise.reject(new Error('builderData transformation failed!'));

    return NUtilitiesService.buildMjml(mjmlJsonObject)
        .then(({ data }) => {
            // BE CAREFUL! RETURN ONLY WHAT'S ABSOLUTELY NECESSARY!
            return Promise.resolve({
                builderData: builderData,
                html: data.html
            });
        })
        .catch((reason) => {
            return Promise.reject(reason);
        });
};

export default EmailTemplateBuilderApi;
