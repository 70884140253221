/****************************************************************************************************
 * BuilderModuleDeclaration model.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 7 November 2020 (added categories)
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const Model = {
    /**
     * Internal name (which also represents the type of module).
     */
    name: 'moduleName',

    /**
     * The name of the MJML declaration.
     *
     * It must be exactly the same as the "name"
     * defined right before the MJML Section in each MJML Module.
     */
    pointsTo: 'mjmlModuleName',

    /**
     * Version of module.
     *
     * It must be exactly the same as the "version"
     * defined right before the MJML Section in each MJML Module.
     */
    version: 1,

    /**
     * Human readable title.
     */
    title: 'Module Title',

    /**
     * Human readable category.
     */
    categories: ['General'],

    /**
     * Preview image URL for the UI.
     */
    previewImageUrl: null,

    /**
     * Display order in UI listings.
     */
    displayOrder: 0,

    /**
     * List of BuilderModulePartDeclaration instances.
     */
    parts: []
};

export default {
    getRaw () {
        return cloneDeep(Model);
    },
    construct (name, pointsTo, version, title, categories, previewImageUrl, displayOrder = 100, parts) {
        let categoriesList;
        if (typeof categories === 'string') {
            categoriesList.push(categories);
        } else if (Array.isArray(categories)) {
            categoriesList = cloneDeep(categories);
        } else {
            categories = [];
        }

        const model = cloneDeep(Model);
        model.name = name;
        model.pointsTo = pointsTo;
        model.version = version;
        model.title = title;
        model.categories = categoriesList;
        model.previewImageUrl = previewImageUrl;
        model.displayOrder = displayOrder;
        model.parts = parts;
        return model;
    }
};
