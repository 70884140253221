<template>
    <div class="EtbModuleList UI-Fullscreen-Column for-view bg-white border-right">
        <div class="UI-Fullscreen-Column__Container">
            <div class="UI-Fullscreen-Column__Body">
                <div class="BuilderModuleCategory"
                     v-for="category in builderModuleDeclarationsByCategoryList"
                     :key="category.rKey">
                    <!-- HEADER -->
                    <div class="BuilderModuleCategory__Header" v-b-toggle="category.rKey">
                        <p class="title">{{ $t(category.title) }}</p>
                    </div>
                    <!-- BODY (LISTING) -->
                    <b-collapse :id="category.rKey"
                                class="BuilderModuleCategory__Body"
                                accordion="my-accordion"
                                role="tabpanel">
                        <div class="AvailableBuilderModule"
                             v-for="builderModuleDeclaration in category.children"
                             :key="builderModuleDeclaration.name"
                             @click="addNewModule(builderModuleDeclaration.name)">
                            <p class="title">{{ $t(builderModuleDeclaration.title) }}</p>
                            <!--suppress HtmlUnknownTarget -->
                            <img :src="builderModuleDeclaration.previewImageUrl"
                                 alt="Cloutlayer Email Template module"
                                 :title="$t(builderModuleDeclaration.title)"/>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * List of modules (and select/new functionality).
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 7 November 2020
 */
export default {
    name: 'EtbModuleList',
    props: {
        builderModuleDeclarationsByCategoryList: {
            type: Array,
            default: function () {
                return [];
            },
            required: true
        }
    },
    methods: {
        addNewModule (name) {
            if (typeof name !== 'string') return;
            if (name.trim() === '') return;
            this.$emit('add-new-module', name);
        }
    }
};
</script>

<style lang="scss">
    .EtbModuleList {

        .UI-Fullscreen-Column__Body {
            overflow-y: scroll;
        }

        .not-collapsed,
        .show {
            background-color: #f4f4f4;
        }
    }

    .BuilderModuleCategory {

        .BuilderModuleCategory__Header {

            :hover {
                background-color: #f4f4f4;
            }
            .title {
                margin: 0;
                position: relative;
                padding: 9px 20px;
                font-size: 14px;
                font-weight: 600;
                color: #727c80;
                -webkit-transition: all .3s;
                transition: all .3s;
                cursor: pointer;
            }
        }

        .BuilderModuleCategory__Body {
            padding: 9px 20px;
        }
    }

    .AvailableBuilderModule {
        position: relative;
        width: 100%;
        margin-bottom: 1.4em;
        cursor: pointer;

        .title {
            margin-bottom: 10px;
            font-size: 12px;
            font-weight: 300;
            line-height: 1.17;
            color: #727c80;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
            border-radius: 4px;
            box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
        }
    }
</style>
