/****************************************************************************************************
 * Button MJML module.
 *
 * See https://mjml.io/documentation/#mj-button
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 27 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const Model = {
    name: 'button',
    version: 1,
    // MJML //////////
    tagName: 'mj-section',
    attributes: {
        'css-class': 'ClEtbModule', // Same for all modules.
        'padding': '25px 25px 25px 25px',
        'background-color': '#ffffff'
    },
    children: [
        {
            tagName: 'mj-column',
            attributes: {
                'padding': '0 0 0 0'
            },
            children: [
                {
                    tagName: 'mj-button',
                    attributes: {
                        'align': 'center',
                        'background-color': '#14103d',
                        'border': 'none',
                        // border-bottom
                        // border-left
                        'border-radius': '3px',
                        // border-right
                        // border-top
                        'color': '#ffffff',
                        // container-background-color
                        // css-class
                        // font-family
                        'font-size': '13px',
                        // font-style
                        'font-weight': 'bold',
                        // 'height': '0',
                        'href': '#',
                        'inner-padding': '20px 40px 20px 40px',
                        // letter-spacing
                        // line-height
                        'padding': '0 0 0 0', // NOT EDITABLE.
                        // padding-bottom
                        // padding-left
                        // padding-right
                        // padding-top
                        // rel
                        'target': '_blank'
                        // text-align
                        // text-decoration
                        // text-transform
                        // vertical-align
                        // 'width': '0'
                    },
                    content: 'Button Text'
                }
            ]
        }
    ]
};

export default {
    getRaw () {
        return cloneDeep(Model);
    },
    getName () {
        return Model.name;
    }
};
