/****************************************************************************************************
 * Basic MJML component declarations such as html, head, body, attribute, font etc.
 *
 * The best way is to structure these components to separate files like 'mjml-module'.
 * But while we have not too many MJML basic components it's more convenient
 * to declare and manage them in a single file.
 * As the project grows this code may need to be refactored.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 27 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

/**
 * See https://www.w3schools.com/cssref/css_websafe_fonts.asp
 */
const WebSafeFontRawList = [
    // The basics //////////
    {
        name: 'serif',
        fontFamily: 'serif'
    },
    {
        name: 'sans-serif',
        fontFamily: 'sans-serif'
    },
    {
        name: 'monospace',
        fontFamily: 'monospace'
    },
    // Serif Fonts //////////
    {
        name: 'Georgia',
        fontFamily: 'Georgia, serif'
    },
    {
        name: 'Palatino',
        fontFamily: 'Palatino, serif'
    },
    {
        name: 'Book Antiqua',
        fontFamily: '\'Book Antiqua\', Palatino, serif'
    },
    {
        name: 'Palatino Linotype',
        fontFamily: '\'Palatino Linotype\', \'Book Antiqua\', Palatino, serif'
    },
    {
        name: 'Times',
        fontFamily: 'Times, serif'
    },
    {
        name: 'Times New Roman',
        fontFamily: '\'Times New Roman\', Times, serif'
    },
    // Sans-Serif Fonts //////////
    {
        name: 'Arial',
        fontFamily: 'Arial, Helvetica, sans-serif'
    },
    {
        name: 'Helvetica',
        fontFamily: 'Helvetica, sans-serif'
    },
    {
        name: 'Gadget',
        fontFamily: 'Gadget, sans-serif\t\n'
    },
    {
        name: 'Arial Black',
        fontFamily: '\'Arial Black\', Gadget, sans-serif'
    },
    {
        name: 'cursive',
        fontFamily: 'cursive, sans-serif'
    },
    {
        name: 'Comic Sans',
        fontFamily: '\'Comic Sans MS\', cursive, sans-serif'
    },
    {
        name: 'Charcoal',
        fontFamily: 'Charcoal, sans-serif'
    },
    {
        name: 'Impact',
        fontFamily: 'Impact, Charcoal, sans-serif'
    },
    {
        name: 'Lucida Grande',
        fontFamily: '\'Lucida Grande\', sans-serif'
    },
    {
        name: 'Lucida Sans Unicode',
        fontFamily: '\'Lucida Sans Unicode\', \'Lucida Grande\', sans-serif'
    },
    {
        name: 'Geneva',
        fontFamily: 'Geneva, sans-serif'
    },
    {
        name: 'Tahoma',
        fontFamily: 'Tahoma, Geneva, sans-serif'
    },
    {
        name: 'Trebuchet',
        fontFamily: '\'Trebuchet MS\', Helvetica, sans-serif'
    },
    {
        name: 'Verdana',
        fontFamily: 'Verdana, Geneva, sans-serif'
    },
    // Monospace Fonts //////////
    {
        name: 'Courier',
        fontFamily: 'Courier, monospace'
    },
    {
        name: 'Courier New',
        fontFamily: '\'Courier New\', Courier, monospace'
    },
    {
        name: 'Monaco',
        fontFamily: 'Monaco, monospace'
    },
    {
        name: 'Lucida Console',
        fontFamily: '\'Lucida Console\', Monaco, monospace'
    }
];

const WebSafeFontList = WebSafeFontRawList.map(function (item) {
    let name = item.name;
    let fontFamily = item.fontFamily;
    return {
        fontFamily: fontFamily,
        tagName: 'mj-font',
        attributes: {
            name: name,
            href: '#'
        }
    };
});

const WebSafeFontMap = WebSafeFontList.reduce(function (accumulator, current) {
    accumulator[current['attributes']['name']] = current;
    return accumulator;
}, {});

/**
 * See https://fonts.google.com/
 *
 * The first font is always the default one.
 */
const GoogleFontRawList = [
    {
        name: 'Comfortaa',
        fontFamily: '\'Comfortaa\', cursive',
        url: 'https://fonts.googleapis.com/css?family=Comfortaa:300,400,700&amp;subset=greek'
    },
    {
        name: 'Roboto',
        fontFamily: '\'Roboto\', \'Helvetica\', sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&amp;subset=greek'
    },
    {
        name: 'Roboto Condensed',
        fontFamily: '\'Roboto Condensed\', \'Helvetica\', sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&amp;subset=greek'
    },
    {
        name: 'Roboto Slab',
        fontFamily: '\'Roboto Slab\', serif',
        url: 'https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700&amp;subset=greek'
    },
    {
        name: 'Open Sans',
        fontFamily: '\'Open Sans\', \'Helvetica\', sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&amp;subset=greek'
    },
    {
        name: 'Source Sans Pro',
        fontFamily: '\'Source Sans Pro\', \'Helvetica\', sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i,900,900i&amp;subset=greek'
    }
];

const GoogleFontList = GoogleFontRawList.map(function (item) {
    let name = item.name;
    let fontFamily = item.fontFamily;
    let url = item.url;
    return {
        fontFamily: fontFamily,
        tagName: 'mj-font',
        attributes: {
            name: name,
            href: url
        }
    };
});

const GoogleFontMap = GoogleFontList.reduce(function (accumulator, current) {
    accumulator[current['attributes']['name']] = current;
    return accumulator;
}, {});

const FontMap = {
    ...WebSafeFontMap,
    ...GoogleFontMap
};

const DefaultFont = FontMap['Roboto'];

const HeadTitle = {
    tagName: 'mj-title',
    content: '', // Title must be empty!
    attributes: {}
};

const HeadPreview = {
    tagName: 'mj-preview',
    content: '[PREVIEW_TEXT]', // Let this as it is to enable auto-fill from EmailCampaign.
    attributes: {}
};

const HeadAttributes = {
    tagName: 'mj-attributes',
    children: [
        {
            tagName: 'mj-all',
            attributes: {
                'font-family': DefaultFont.fontFamily
            }
        }
    ],
    attributes: {}
};

const Head = {
    tagName: 'mj-head',
    children: [
        HeadTitle,
        DefaultFont,
        HeadAttributes,
        HeadPreview
    ],
    attributes: {}
};

const Body = {
    tagName: 'mj-body',
    attributes: {
        'background-color': '#f4f4f4',
        'width': '600px',
        'css-class': 'ClEtbModuleList'
    },
    children: []
};

const Root = {
    tagName: 'mjml',
    attributes: {},
    children: [
        Head,
        Body
    ]
};

export default {
    getRoot () {
        return cloneDeep(Root);
    },
    getFontByName (name) {
        if (typeof name !== 'string') return null;
        if (name.trim() === '') return null;
        if (!FontMap.hasOwnProperty(name)) return null;
        return cloneDeep(FontMap[name]);
    },
    getDefaultFont () {
        return cloneDeep(DefaultFont);
    },
    getFontListAsOptions () {
        return cloneDeep((WebSafeFontRawList.concat(GoogleFontRawList))
            .map(function (item) {
                return {
                    label: item.name,
                    value: item.name
                };
            })
            .sort(function (a, b) {
                return ('' + a.name).localeCompare(b.name);
            }));
    }
};
