/****************************************************************************************************
 * BuilderModulePartFieldDeclaration model.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 29 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const Model = {
    /**
     * Internal name.
     *
     * IMPORTANT: Must be unique in BuilderModuleDeclaration
     * among all BuilderModulePartDeclaration instances.
     */
    name: 'modulePartFieldName',

    /**
     * MJML Object path to field.
     *
     * When type is NOT 'group' and referring to modules:
     *   Start point is the root MJML tag (absolute path).
     *   E.g.: mjml > mjml body > mjml section > mjml column > mjml image > attributes > src (set data)
     *
     * When type is 'group' and referring to modules:
     *   Start point is the root MJML tag (absolute path).
     *   It is assumed that data is actually an array.
     *   E.g.: mjml > mjml body > mjml section > mjml column > mjml navbar > children (set data)
     *
     * When type is NOT 'group' and referring to submodules:
     *   Start point is the MJML component itself (relative path).
     *   E.g. mjml navbar link > attributes > font-size (set data)
     *
     * When type is 'group' and referring to submodules:
     *   NOT ALLOWED - INVALID!
     */
    mjmlPath: null,

    /**
     * The name of the MJML declaration (module or submodule).
     *
     * It must be exactly the same as the "name"
     * defined right before the MJML Section in each MJML Module.
     *
     * AVOID using this when type is NOT 'group'.
     * AVOID using this on modules! Use on submodules instead.
     */
    pointsTo: null,

    /**
     * Human readable title.
     */
    title: 'Module Part Field Title',

    /**
     * The type of field (and UI input control).
     *
     * Values:
     * - group: grouped fields (their values represent a single instance)
     * - text: Simple text in input or textarea with basic validation.
     * - select: Select control.
     *   Use this for cases where selected option value is used directly by MJML (without transformation).
     *   Of course you can add logic to these options by creating a new getter with a custom 'resolveData' function.
     * - number: Integer number (negative, 0, positive).
     * - color: Any color format. The default is hex. Try to stick with hex.
     * - spacing: Spacing refers to margin and padding. All formats are supported.
     *   Check specificType field for more information on formats.
     * - border: CSS Border.
     */
    type: 'text',

    /**
     * A type that helps Model and Logic providers
     * decide how to treat the value of this field.
     *
     * It is complementary(!) to the type.
     *
     * Example: there is no need to set 'specificType = alignment'
     * as we treat alignment as string which can only be selected from select controls.
     * If that changes in the future we will proceed to the corresponding refactors.
     * Until then YAGNI.
     *
     * When type is 'text'   : specificType can be 'textUrl' or null. Default is null.
     * When type is 'spacing': specificType can be 'x|y|xy|full|topbottom|leftright|top|right|bottom|left' or null. Default is 'full'.
     * When type is 'number' : specificType can be 'pixels' or null. Default is null.
     */
    specificType: null,

    /**
     * Default value.
     *
     * It can be any value.
     * It's up to the corresponding component to use or not this value.
     */
    defaultValue: null,

    /**
     * Min number in case of numeric field.
     * Min characters length in case of text field.
     */
    min: null,

    /**
     * Max number in case of numeric field.
     * Max characters length in case of text field.
     */
    max: null,

    /**
     * Options list in case of 'select'.
     *
     * Each option item should contain at least the 'label' and the 'value' fields.
     */
    options: [],

    /**
     * Dictionary with options for the UI.
     *
     * It's up to each -customizer- component to retrieve and use corresponding data.
     */
    uiOptions: {
        step: 1,
        toStringField: null // If it's group, this field describes the list item. The field's value must be a string!
    },

    /**
     * Children declaration (exactly the same type ie BuilderModulePartFieldDeclaration).
     */
    children: []
};

function convertNumberToPixel (num) {
    if (typeof num !== 'number') return '0';
    if (num === 0) return '0';
    return num + 'px';
}

export default {
    getRaw () {
        return cloneDeep(Model);
    },

    getGroup (name, mjmlPath, pointsTo, title, toStringField = null, defaultValue = [], children = []) {
        const model = cloneDeep(Model);

        if (typeof toStringField !== 'string') toStringField = null;
        if (!Array.isArray(defaultValue)) defaultValue = [];

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.pointsTo = pointsTo;
        model.title = title;
        model.type = 'group';
        model.defaultValue = defaultValue;
        model.children = children;
        model.uiOptions.toStringField = toStringField;

        model.resolveData = function (data) {
            throw new Error('BuilderModulePartFieldDeclaration of type "group" does not support "resolveData"');
        };

        return model;
    },

    getText (name, mjmlPath, title, defaultValue = '', min = 0, max = 1000) {
        const model = cloneDeep(Model);

        if (typeof defaultValue !== 'string') defaultValue = '';
        if (typeof min !== 'number') min = 0;
        if (typeof max !== 'number') max = 1000;
        if (min < 0) min = 0;

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'text';
        model.defaultValue = defaultValue;
        model.min = min;
        model.max = max;
        model.resolveData = function (data) {
            if (typeof data !== 'string') return model.defaultValue;
            return data;
        };

        return model;
    },
    getTextLink (name, mjmlPath, title, defaultValue = '') {
        const model = cloneDeep(Model);

        if (typeof defaultValue !== 'string') defaultValue = '';

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'text';
        model.specificType = 'textUrl';
        model.defaultValue = defaultValue;
        model.min = 0;
        model.max = 2000;

        model.resolveData = function (data) {
            if (typeof data !== 'string') return model.defaultValue;
            return data;
        };

        return model;
    },
    getPercentage (name, mjmlPath, title, defaultValue = 100, min = 0, max = 300, step = 5) {
        const model = cloneDeep(Model);

        if (typeof defaultValue !== 'number') defaultValue = 100;
        if (typeof min !== 'number') min = 0;
        if (min < 0) min = 0;
        if (typeof max !== 'number') max = 1000;
        if (max < 0) max = 300;
        if (typeof step !== 'number') step = 5;
        if (step <= 0) step = 5;

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'number';
        model.specificType = 'percentage';
        model.defaultValue = defaultValue;
        model.min = min;
        model.max = max;
        model.uiOptions.step = step;

        model.resolveData = function (data) {
            if (typeof data === 'string') data = parseInt(data, 10);
            if (typeof data !== 'number') return model.defaultValue + '%';
            return data + '%';
        };

        return model;
    },

    /**
     * Get Image field.
     *
     * @param name the name
     * @param mjmlPath the MJML path
     * @param title the title
     * @param defaultValue it is used as preview more than it is used as fallback or default value!
     * @return {*} the declaration
     */
    getImage (name, mjmlPath, title, defaultValue = '') {
        const model = cloneDeep(Model);

        if (typeof defaultValue !== 'string') defaultValue = '';

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'file';
        model.specificType = 'image';
        model.defaultValue = defaultValue;

        model.resolveData = function (data) {
            // preview image cannot be used as fallback! Dangerous.
            // It's preferable to display no image at all than a wrong image.
            if (typeof data !== 'string') return ''; // Empty string will disable image's link.
            return data;
        };

        return model;
    },
    getRichText (name, mjmlPath, title, defaultValue = '') {
        const model = cloneDeep(Model);

        if (typeof defaultValue !== 'string') defaultValue = '';

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'richText';
        model.defaultValue = defaultValue;
        model.min = 0;
        model.max = 10000;
        model.resolveData = function (data) {
            if (typeof data !== 'string') return model.defaultValue;
            return data;
        };

        return model;
    },
    getSelect (name, mjmlPath, title, defaultValue, options = []) {
        const model = cloneDeep(Model);

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'select';
        model.defaultValue = defaultValue;
        model.options = cloneDeep(options);

        model.resolveData = function (data) {
            const anyMatch = model.options.some(function (item) {
                return item.value === data;
            });
            if (anyMatch) return data;
            return model.defaultValue;
        };

        return model;
    },
    getPixels (name, mjmlPath, title, defaultValue = 10, min = 0, max = 1000, step = 1) {
        const model = cloneDeep(Model);

        if (typeof defaultValue !== 'number') defaultValue = 10;
        if (typeof min !== 'number') min = 0;
        if (typeof max !== 'number') max = 1000;
        if (typeof step !== 'number') step = 1;
        if (step <= 0) step = 1;

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'number';
        model.specificType = 'pixels';
        model.defaultValue = defaultValue;
        model.min = min;
        model.max = max;
        model.uiOptions.step = step;

        model.resolveData = function (data) {
            if (typeof data === 'string') data = parseInt(data, 10);
            if (typeof data !== 'number') return model.defaultValue + 'px';
            return data + 'px';
        };

        return model;
    },
    getColor (name, mjmlPath, title, defaultValue = null) {
        const model = cloneDeep(Model);

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'color';
        model.defaultValue = defaultValue;

        model.resolveData = function (data) {
            if (typeof data !== 'string') return 'transparent'; // Required by MJML (otherwise it will fail).
            return data;
        };

        return model;
    },
    getSpacing (name, mjmlPath, title, specificType = 'full', defaultValue = { top: 0, right: 0, bottom: 0, left: 0, x: 0, y: 0 }, min = 0, max = 200, step = 1) {
        const model = cloneDeep(Model);

        if (typeof specificType !== 'string') specificType = 'full';
        if (specificType !== 'x' &&
            specificType !== 'y' &&
            specificType !== 'xy' &&
            specificType !== 'full' &&
            specificType !== 'topbottom' &&
            specificType !== 'leftright' &&
            specificType !== 'top' &&
            specificType !== 'right' &&
            specificType !== 'bottom' &&
            specificType !== 'left') {
            specificType = 'full';
        }

        if (typeof defaultValue !== 'object') {
            defaultValue = {};
        }

        if (typeof defaultValue['top'] !== 'number') defaultValue['top'] = 0;
        if (typeof defaultValue['bottom'] !== 'number') defaultValue['bottom'] = 0;
        if (typeof defaultValue['left'] !== 'number') defaultValue['left'] = 0;
        if (typeof defaultValue['right'] !== 'number') defaultValue['right'] = 0;
        if (typeof defaultValue['x'] !== 'number') defaultValue['x'] = 0;
        if (typeof defaultValue['y'] !== 'number') defaultValue['y'] = 0;

        if (typeof step !== 'number') step = 1;
        if (step <= 0) step = 1;

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'spacing';
        model.specificType = specificType;
        model.defaultValue = defaultValue;
        model.min = min;
        model.max = max;
        model.uiOptions.step = step;

        // IMPORTANT 1: This function returns the full spacing ready to be used in 'margin' or 'padding'.
        // This is NOT margin-top, margin-right, margin-bottom, margin-left, padding-top, padding-right, padding-bottom, padding-left!
        // IMPORTANT 2: The fields which are not provided by user, are filled with the values from defaultValue object.
        model.resolveData = function (data) {
            switch (model.specificType) {
            case 'x':
                return [
                    convertNumberToPixel(model.defaultValue.y),
                    convertNumberToPixel(data.x),
                    convertNumberToPixel(model.defaultValue.y),
                    convertNumberToPixel(data.x)
                ].join(' ');
            case 'y':
                return [
                    convertNumberToPixel(data.y),
                    convertNumberToPixel(model.defaultValue.x),
                    convertNumberToPixel(data.y),
                    convertNumberToPixel(model.defaultValue.x)
                ].join(' ');
            case 'xy':
                return [
                    convertNumberToPixel(data.y),
                    convertNumberToPixel(data.x),
                    convertNumberToPixel(data.y),
                    convertNumberToPixel(data.x)
                ].join(' ');
            case 'full':
                return [
                    convertNumberToPixel(data.top),
                    convertNumberToPixel(data.right),
                    convertNumberToPixel(data.bottom),
                    convertNumberToPixel(data.left)
                ].join(' ');
            case 'topbottom':
                return [
                    convertNumberToPixel(data.top),
                    convertNumberToPixel(model.defaultValue.right),
                    convertNumberToPixel(data.bottom),
                    convertNumberToPixel(model.defaultValue.left)
                ].join(' ');
            case 'leftright':
                return [
                    convertNumberToPixel(model.defaultValue.top),
                    convertNumberToPixel(data.right),
                    convertNumberToPixel(model.defaultValue.bottom),
                    convertNumberToPixel(data.left)
                ].join(' ');
            case 'top':
                return [
                    convertNumberToPixel(data.top),
                    convertNumberToPixel(model.defaultValue.right),
                    convertNumberToPixel(model.defaultValue.bottom),
                    convertNumberToPixel(model.defaultValue.left)
                ].join(' ');
            case 'right':
                return [
                    convertNumberToPixel(model.defaultValue.top),
                    convertNumberToPixel(data.right),
                    convertNumberToPixel(model.defaultValue.bottom),
                    convertNumberToPixel(model.defaultValue.left)
                ].join(' ');
            case 'bottom':
                return [
                    convertNumberToPixel(model.defaultValue.top),
                    convertNumberToPixel(model.defaultValue.right),
                    convertNumberToPixel(data.bottom),
                    convertNumberToPixel(model.defaultValue.left)
                ].join(' ');
            case 'left':
                return [
                    convertNumberToPixel(model.defaultValue.top),
                    convertNumberToPixel(model.defaultValue.right),
                    convertNumberToPixel(model.defaultValue.bottom),
                    convertNumberToPixel(data.left)
                ].join(' ');
            default:
                return null; // Theoretically unreachable.
            }
        };

        return model;
    },
    getBorder (name, mjmlPath, title, defaultValue = { size: 0, style: 'solid', color: '#0000ff' }, min = 0, max = 20, step = 1) {
        const model = cloneDeep(Model);

        if (typeof min !== 'number') min = 0;
        if (min < 0) min = 0;
        if (typeof max !== 'number') max = 0;
        if (max < 0) max = 20;

        if (typeof step !== 'number') step = 1;
        if (step <= 0) step = 1;

        model.name = name;
        model.mjmlPath = mjmlPath;
        model.title = title;
        model.type = 'border';
        model.defaultValue = defaultValue;
        model.min = min;
        model.max = max;
        model.uiOptions.step = step;

        model.resolveData = function (data) {
            return [data.size, 'px', ' ', data.style, ' ', data.color].join('');
        };

        return model;
    }
};
