/****************************************************************************************************
 * BuilderModulePartDeclaration model.
 *
 * Groups similar BuilderModulePartDeclaration instances.
 * It represents a small independent visual part in a module.
 * It groups the fields that refer to that visual part.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const Model = {
    /**
     * Internal name.
     *
     * Must be unique in BuilderModuleDeclaration 'fields' list.
     */
    name: 'modulePartFieldName',

    /**
     * Human readable title.
     */
    title: 'Module Part Title',

    /**
     * A flags that declares if this part can be hidden from HTML body.
     */
    canBeDisabled: false,

    /**
     * List of BuilderModulePartFieldDeclaration instances.
     */
    fields: []
};

export default {
    getRaw () {
        return cloneDeep(Model);
    },
    construct (name, title, canBeDisabled, fields) {
        const model = cloneDeep(Model);
        model.name = name;
        model.title = title;
        model.canBeDisabled = canBeDisabled;
        model.fields = fields;
        return model;
    }
};
