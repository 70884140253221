/****************************************************************************************************
 * MJML Module repository.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 29 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

import ButtonModule from '../mjml-module/button-module.model';
import ImageModule from '../mjml-module/image-module.model';
import NavbarModule from '../mjml-module/navbar-module.model';
import NavbarLogoModule from '../mjml-module/navbar-logo-module.model';
import SpacingModule from '../mjml-module/spacing-module.model';
import TextModule from '../mjml-module/text-module.model';

const MjmlModuleMap = {
    [ButtonModule.getName()]: ButtonModule.getRaw(),
    [ImageModule.getName()]: ImageModule.getRaw(),
    [NavbarModule.getName()]: NavbarModule.getRaw(),
    [NavbarLogoModule.getName()]: NavbarLogoModule.getRaw(),
    [SpacingModule.getName()]: SpacingModule.getRaw(),
    [TextModule.getName()]: TextModule.getRaw()
};

export default {
    /**
     * Return a deep clone of all MjmlModuleMap as a map.
     */
    getMjmlModuleMapMap () {
        return cloneDeep(MjmlModuleMap);
    },

    /**
     * Get the "name" MJML Module or null if does not exist.
     */
    getMjmlModuleByName (name) {
        if (typeof name !== 'string') return null;
        if (!MjmlModuleMap.hasOwnProperty(name)) return null;
        return cloneDeep(MjmlModuleMap[name]);
    },

    /**
     * Check if the "name" MJML Module exists.
     */
    doesMjmlModuleExists (name) {
        if (typeof name !== 'string') return false;
        return MjmlModuleMap.hasOwnProperty(name);
    }
};
