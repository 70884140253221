<template>
    <div class="EtbModuleCustomizerControlRichText">
        <ckeditor :editor="editor"
                  v-model="dataValue"
                  :config="editorConfig"></ckeditor>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@ckeditor/ckeditor5-build-classic/build/translations/el';

/**
 * Module Customizer Control for Rich Text.
 *
 * @author Dimitris Gkoulis
 * @createdAt 29 October 2020
 * @lastModifiedAt 14 November 2020
 */
export default {
    name: 'EtbModuleCustomizerControlRichText',
    components: {
        ckeditor: CKEditor.component
    },
    props: {
        dataValueProvided: {
            type: String,
            default: null,
            required: false
        }
    },
    data () {
        return {
            dataValue: null,

            // CKEditor //////////
            editor: ClassicEditor,
            editorConfig: {
                language: 'en',
                toolbar: {
                    items: [
                        'heading',
                        'bold',
                        'italic',
                        'link',
                        'undo',
                        'redo'
                    ]
                }
            }
        };
    },
    beforeMount () {
        // Set editor language based on user's language.
        this.editorConfig.language = this.getAppLocale();

        this.dataValue = cloneDeep(this.dataValueProvided);

        this.$watch('dataValue', {
            handler: function (ignoredValue) {
                this.$emit('change', cloneDeep(this.dataValue));
            },
            deep: true,
            immediate: false
        });
    }
};
</script>

<style lang="scss">
    .EtbModuleCustomizerControlRichText {

        .ck-editor > .ck-label {
            display: none !important;
        }
    }
</style>
