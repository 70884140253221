/****************************************************************************************************
 * Builder Module repository helper (just a helper to reduce main file's size and complexity - CLEAN CODE).
 *
 * @author Dimitris Gkoulis
 * @createdAt 27 October 2020
 * @lastModifiedAt 9 November 2020 (translations)
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

import BuilderModulePartDeclarationModel from '../builder-declaration/builder-module-part-declaration.model';
import BuilderModulePartFieldDeclarationModel from '../builder-declaration/builder-module-part-field-declaration.model';

export const SETTINGS_WIDTH = 600;
export const SETTINGS_MIN_WIDTH = 400;
export const SETTINGS_MAX_WIDTH = 1200; // It also used as max width in images and other components that can be "full-width".

// IMPORTANT Make sure that these images are public.
const SAMPLE_LOGO_01 = 'https://www.cloutlayer.com/media/2020/10/images/Love4Ocean-Logo.original.png';
const SAMPLE_IMAGE_01 = 'https://www.cloutlayer.com/media/2020/10/images/Protecting-our-oceans-0001-min.original.png';
// const SAMPLE_IMAGE_02 = 'https://www.cloutlayer.com/media/2020/10/images/Protecting-our-oceans-0002-min.original.png';

const LINE_HEIGHT_OPTIONS = [];

for (let i = 1; i <= 3; i = i + 0.1) {
    const num = i.toFixed(1);
    LINE_HEIGHT_OPTIONS.push({
        label: num + '',
        value: num + ''
    });
}

const BACKGROUND_REPEAT_OPTIONS = [
    {
        label: 'Repeat',
        value: 'repeat'
    },
    {
        label: 'No Repeat',
        value: 'no-repeat'
    },
    {
        label: 'Repeat X',
        value: 'repeat-x'
    },
    {
        label: 'Repeat Y',
        value: 'repeat-y'
    }
];

const FONT_WEIGHT_OPTIONS = [
    {
        label: 'Light',
        value: 'lighter'
    },
    {
        label: 'Normal',
        value: 'normal'
    },
    {
        label: 'Bold',
        value: 'bold'
    },
    {
        label: 'Extra Bold',
        value: 'bolder'
    }
];

const FONT_WEIGHT_NUMBER_OPTIONS = [
    {
        label: '100',
        value: 100
    },
    {
        label: '200',
        value: 200
    },
    {
        label: '300',
        value: 300
    },
    {
        label: '400',
        value: 400
    },
    {
        label: '500',
        value: 500
    },
    {
        label: '600',
        value: 600
    },
    {
        label: '700',
        value: 700
    },
    {
        label: '800',
        value: 800
    },
    {
        label: '900',
        value: 900
    }
];

const ALIGNMENT_OPTIONS = [
    {
        label: 'Left',
        value: 'left'
    },
    {
        label: 'Center',
        value: 'center'
    },
    {
        label: 'Right',
        value: 'right'
    }
];

const ALIGNMENT_OPTIONS_WITH_JUSTIFY = [
    {
        label: 'Left',
        value: 'left'
    },
    {
        label: 'Center',
        value: 'center'
    },
    {
        label: 'Right',
        value: 'right'
    },
    {
        label: 'Justify',
        value: 'justify'
    }
];

export function getCommonSectionDeclaration (backgroundColor = '#ffffff', padding = { top: 25, right: 25, bottom: 25, left: 25 }) {
    const declaration = BuilderModulePartDeclarationModel.construct('section', 'Section', false, [
        BuilderModulePartFieldDeclarationModel.getColor('sectionBackgroundColor', ['attributes', 'background-color'], 'Background', backgroundColor),
        BuilderModulePartFieldDeclarationModel.getSpacing('sectionPadding', ['attributes', 'padding'], 'Inner Spacing', 'full', padding),
        BuilderModulePartFieldDeclarationModel.getBorder('sectionBorder', ['attributes', 'border'], 'Border'),
        BuilderModulePartFieldDeclarationModel.getPixels('sectionBorderRadius', ['attributes', 'border-radius'], 'Border Radius', 0, 0, 100)
    ]);
    return cloneDeep(declaration);
}

export function getCommonSectionImageDeclaration () {
    const declaration = BuilderModulePartDeclarationModel.construct('sectionImage', 'Section Image', true, [
        BuilderModulePartFieldDeclarationModel.getImage('sectionBackgroundUrl', ['attributes', 'background-url'], 'Background Image', ''),
        BuilderModulePartFieldDeclarationModel.getPercentage('sectionBackgroundSize', ['attributes', 'background-size'], 'Background Image Size', 140, 0, 300),
        BuilderModulePartFieldDeclarationModel.getSelect('sectionBackgroundRepeat', ['attributes', 'background-repeat'], 'Background Image Repeat', 'repeat', BACKGROUND_REPEAT_OPTIONS)
    ]);
    return cloneDeep(declaration);
}

export function getCommonColumnDeclaration (order = 0, width = 100) {
    const declaration = BuilderModulePartDeclarationModel.construct(`column${order}`, `Column #${(order + 1)}`, true, [
        BuilderModulePartFieldDeclarationModel.getPercentage(`column${order}Width`, ['children', order, 'attributes', 'width'], 'Column Width', width, 0, 100),
        BuilderModulePartFieldDeclarationModel.getSpacing(`column${order}Padding`, ['children', order, 'attributes', 'padding'], 'Column Inner Spacing', 'full', { top: 0, right: 0, bottom: 0, left: 0 })
    ]);
    return cloneDeep(declaration);
}

export function getCommonImageDeclaration (order = 0, imageSrc = SAMPLE_IMAGE_01, imageWidth = 200, imageAlignment = 'center') {
    const declaration = BuilderModulePartDeclarationModel.construct('image', 'Image', false, [
        BuilderModulePartFieldDeclarationModel.getImage('imageSrc', ['children', order, 'children', 0, 'attributes', 'src'], 'Image', imageSrc),
        BuilderModulePartFieldDeclarationModel.getPixels('imageWidth', ['children', order, 'children', 0, 'attributes', 'width'], 'Width', imageWidth, 0, SETTINGS_MAX_WIDTH, 10),
        BuilderModulePartFieldDeclarationModel.getSelect('imageAlignment', ['children', order, 'children', 0, 'attributes', 'align'], 'Alignment', imageAlignment, ALIGNMENT_OPTIONS)
    ]);
    return cloneDeep(declaration);
}

// Assumption: a mj-image under a mj-column under a mj-section.
export function getCommonImageLinkDeclaration (order = 0) {
    const declaration = BuilderModulePartDeclarationModel.construct('imageLink', 'Image Link', false, [
        BuilderModulePartFieldDeclarationModel.getTextLink('imageHref', ['children', order, 'children', 0, 'attributes', 'href'], 'Link', '')
    ]);
    return cloneDeep(declaration);
}

export function getCommonNavbarLinksDeclaration (order = 0, defaultValue = []) {
    if (!Array.isArray(defaultValue)) defaultValue = [];

    const declaration = BuilderModulePartFieldDeclarationModel.getGroup('navbarLinks', ['children', order, 'children', 0, 'children'], 'navbar-link', 'Links', 'navbarLinkContent', defaultValue, [
        BuilderModulePartFieldDeclarationModel.getText('navbarLinkContent', ['content'], 'Title', 'Link'),
        BuilderModulePartFieldDeclarationModel.getTextLink('navbarLinkHref', ['attributes', 'href'], 'Link', '#'),
        BuilderModulePartFieldDeclarationModel.getColor('navbarLinkColor', ['attributes', 'color'], 'Color', '#ffffff'),
        BuilderModulePartFieldDeclarationModel.getPixels('navbarLinkFontSize', ['attributes', 'font-size'], 'Size', 16, 8, 36),
        BuilderModulePartFieldDeclarationModel.getSelect('navbarLinkFontWeight', ['attributes', 'font-weight'], 'Weight', 500, FONT_WEIGHT_NUMBER_OPTIONS),
        BuilderModulePartFieldDeclarationModel.getPixels('navbarLinkLineHeight', ['attributes', 'line-height'], 'Line Height', 22, 8, 36),
        BuilderModulePartFieldDeclarationModel.getSpacing('navbarLinkPadding', ['attributes', 'padding'], 'Spacing', 'full', { top: 0, right: 15, bottom: 0, left: 0 }, 0, 100)
    ]);
    return cloneDeep(declaration);
}

export function getCommonNavbarLinksDefaultValue (names = ['Link #1', 'Link #2', 'Link #3']) {
    if (!Array.isArray(names)) return [];
    return names.map(function (name) {
        return {
            navbarLinkColor: '#ffffff',
            navbarLinkContent: name,
            navbarLinkFontSize: 16,
            navbarLinkFontWeight: 500,
            navbarLinkHref: '#',
            navbarLinkLineHeight: 22,
            navbarLinkPadding: {
                top: 0,
                right: 15,
                bottom: 0,
                left: 0,
                x: 0,
                y: 0
            }
        };
    });
}

export default {
    getSampleLogo01 () {
        return SAMPLE_LOGO_01;
    },
    getSampleImage01 () {
        return SAMPLE_IMAGE_01;
    },
    getLoremIpsumText () {
        return '<h2>Lorem ipsum dolor sit amet</h2>' +
            '<p>Duis tincidunt sapien sed diam lobortis, in aliquet quam aliquet. ' +
            'Donec tellus nulla, vehicula at massa ut, dignissim pellentesque urna. ' +
            'Nunc pulvinar, risus at sollicitudin mollis, risus arcu sollicitudin justo, ' +
            'ac condimentum velit risus nec nunc. Maecenas at scelerisque leo. ' +
            'Etiam condimentum nunc maximus diam mattis, sit amet convallis turpis viverra.</p>' +
            '<p>Nunc magna lorem, elementum vitae volutpat vitae, elementum nec lorem. ' +
            'Curabitur a orci fringilla, auctor ex at, suscipit nulla. ' +
            'Mauris volutpat diam vitae nisl lacinia, ut varius dui pretium. ' +
            'Nulla eleifend efficitur tincidunt. ' +
            'Donec venenatis mattis lacus, id sollicitudin velit congue et.</p>';
    },
    getLineHeightOptions () {
        return cloneDeep(LINE_HEIGHT_OPTIONS);
    },
    getFontWeightOptions () {
        return cloneDeep(FONT_WEIGHT_OPTIONS);
    },
    getFontWeightNumberOptions () {
        return cloneDeep(FONT_WEIGHT_NUMBER_OPTIONS);
    },
    getAlignmentOptions () {
        return cloneDeep(ALIGNMENT_OPTIONS);
    },
    getAlignmentOptionsWithJustify () {
        return cloneDeep(ALIGNMENT_OPTIONS_WITH_JUSTIFY);
    }
};
