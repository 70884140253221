<template>
    <div class="EtbModuleCustomizerControl">
        <div v-if="builderModulePartFieldDeclaration.type === 'text'" key="control-text">
            <div class="el-form-group">
                <label :for="getUniqueElementId(builderModulePartFieldDeclaration.name)">{{ $t(builderModulePartFieldDeclaration.title) }}</label>
                <el-input :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                          type="textarea"
                          autosize
                          v-model="dataValue"
                          :minlength="builderModulePartFieldDeclaration.min"
                          :maxlength="builderModulePartFieldDeclaration.max"></el-input>
            </div>
        </div>
        <div v-else-if="builderModulePartFieldDeclaration.type === 'richText'" key="control-richText">
            <div class="el-form-group">
                <label :for="getUniqueElementId(builderModulePartFieldDeclaration.name)">{{ $t(builderModulePartFieldDeclaration.title) }}</label>
                <etb-module-customizer-control-rich-text :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                                         :data-value-provided="dataValue"
                                                         v-on:change="handleEtbModuleCustomizerControlRichTextChange"></etb-module-customizer-control-rich-text>
            </div>
        </div>
        <div v-else-if="builderModulePartFieldDeclaration.type === 'file'" key="control-file">
            <div class="el-form-group">
                <label :for="getUniqueElementId(builderModulePartFieldDeclaration.name)">{{ $t(builderModulePartFieldDeclaration.title) }}</label>
                <div v-if="builderModulePartFieldDeclaration.specificType === 'image'"
                     key="control-file-image">
                    <etb-module-customizer-control-file-image :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                                              :data-value-provided="dataValue"
                                                              v-on:change="handleEtbModuleCustomizerControlFileImageChange"></etb-module-customizer-control-file-image>
                </div>
                <div v-else
                     key="control-file-unknown">
                    <p class="mb-0 text-danger small font-weight-bolder">{{ $t('Invalid') }} - {{ $t('Please contact support') }}</p>
                    <p class="mb-0 text-warning small font-weight-normal">{{ $t(builderModulePartFieldDeclaration.title) }} / {{ builderModulePartFieldDeclaration.type }} / {{ builderModulePartFieldDeclaration.specificType }}</p>
                </div>
            </div>
        </div>
        <div v-else-if="builderModulePartFieldDeclaration.type === 'select'" key="control-select">
            <div class="el-form-group">
                <label :for="getUniqueElementId(builderModulePartFieldDeclaration.name)">{{ $t(builderModulePartFieldDeclaration.title) }}</label>
                <el-select :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                           v-model="dataValue">
                    <el-option v-for="option in builderModulePartFieldDeclaration.options"
                               :value="option.value"
                               :label="$t(option.label)"
                               :key="option.value">
                        {{ $t(option.label) }}
                    </el-option>
                </el-select>
            </div>
        </div>
        <div v-else-if="builderModulePartFieldDeclaration.type === 'number'" key="control-number">
            <div class="el-form-group">
                <label :for="getUniqueElementId(builderModulePartFieldDeclaration.name)">{{ $t(builderModulePartFieldDeclaration.title) }}</label>
                <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                 v-model="dataValue"
                                 :step="builderModulePartFieldDeclaration.uiOptions.step"
                                 :min="builderModulePartFieldDeclaration.min"
                                 :max="builderModulePartFieldDeclaration.max"></el-input-number>
            </div>
        </div>
        <div v-else-if="builderModulePartFieldDeclaration.type === 'color'" key="control-color">
            <div class="el-form-group">
                <label :for="getUniqueElementId(builderModulePartFieldDeclaration.name)">{{ $t(builderModulePartFieldDeclaration.title) }}</label>
                <el-color-picker :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                 v-model="dataValue"></el-color-picker>
            </div>
        </div>
        <div v-else-if="builderModulePartFieldDeclaration.type === 'spacing'" key="control-spacing">
            <div class="el-form-group el-form-group--etb--spacing">
                <label :for="getUniqueElementId(builderModulePartFieldDeclaration.name)">
                    {{ $t(builderModulePartFieldDeclaration.title) }}
                    <small v-if="labelHelpText">[{{ $t(labelHelpText) }}]</small>
                </label>
                <div class="d-flex justify-content-between align-items-center"
                     v-if="builderModulePartFieldDeclaration.specificType === 'x'"
                     key="control-spacing-x">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     v-model="dataValue.x"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     v-else-if="builderModulePartFieldDeclaration.specificType === 'y'"
                     key="control-spacing-y">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     v-model="dataValue.y"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     v-else-if="builderModulePartFieldDeclaration.specificType === 'xy'"
                     key="control-spacing-xy">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     controls-position="right"
                                     v-model="dataValue.y"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name) + '_2'"
                                     class="ml-2"
                                     controls-position="right"
                                     v-model="dataValue.x"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     v-else-if="builderModulePartFieldDeclaration.specificType === 'full'"
                     key="control-spacing-full">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     controls-position="right"
                                     size="mini"
                                     prefix-icon="el-icon-search"
                                     v-model="dataValue.top"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name + '_2')"
                                     class="ml-2"
                                     controls-position="right"
                                     size="mini"
                                     v-model="dataValue.bottom"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name + '_3')"
                                     class="ml-2"
                                     controls-position="right"
                                     size="mini"
                                     v-model="dataValue.left"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name + '_4')"
                                     class="ml-2"
                                     controls-position="right"
                                     size="mini"
                                     v-model="dataValue.right"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     v-else-if="builderModulePartFieldDeclaration.specificType === 'topbottom'"
                     key="control-spacing-topbottom">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     controls-position="right"
                                     v-model="dataValue.top"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name + '_2')"
                                     class="ml-2"
                                     controls-position="right"
                                     v-model="dataValue.bottom"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     v-else-if="builderModulePartFieldDeclaration.specificType === 'leftright'"
                     key="control-spacing-topbottom">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     controls-position="right"
                                     v-model="dataValue.left"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name + '_2')"
                                     class="ml-2"
                                     controls-position="right"
                                     v-model="dataValue.right"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     v-else-if="builderModulePartFieldDeclaration.specificType === 'top'"
                     key="control-spacing-y">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     v-model="dataValue.top"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     v-else-if="builderModulePartFieldDeclaration.specificType === 'right'"
                     key="control-spacing-y">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     v-model="dataValue.right"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     v-else-if="builderModulePartFieldDeclaration.specificType === 'bottom'"
                     key="control-spacing-y">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     v-model="dataValue.bottom"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     v-else-if="builderModulePartFieldDeclaration.specificType === 'left'"
                     key="control-spacing-y">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     v-model="dataValue.left"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                </div>
                <div v-else key="control-spacing-unknown">
                    <p class="mb-0 text-danger small font-weight-bolder">{{ $t('Invalid') }} - {{ $t('Please contact support') }}</p>
                    <p class="mb-0 text-warning small font-weight-normal">{{ $t(builderModulePartFieldDeclaration.title) }} / {{ builderModulePartFieldDeclaration.type }} / {{ builderModulePartFieldDeclaration.specificType }}</p>
                </div>
            </div>
        </div>
        <div v-else-if="builderModulePartFieldDeclaration.type === 'border'" key="control-select">
            <div class="el-form-group">
                <label :for="getUniqueElementId(builderModulePartFieldDeclaration.name)">{{ $t(builderModulePartFieldDeclaration.title) }}</label>
                <div class="d-flex justify-content-between align-items-center">
                    <el-input-number :id="getUniqueElementId(builderModulePartFieldDeclaration.name)"
                                     controls-position="right"
                                     :step="builderModulePartFieldDeclaration.uiOptions.step"
                                     v-model="dataValue.size"
                                     :min="builderModulePartFieldDeclaration.min"
                                     :max="builderModulePartFieldDeclaration.max"></el-input-number>
                    <el-color-picker :id="getUniqueElementId(builderModulePartFieldDeclaration.name + '_2')"
                                     v-model="dataValue.color"
                                     class="ml-2"
                                     v-if="dataValue.size > 0"></el-color-picker>
                </div>
            </div>
        </div>
        <div v-else key="control-unknown">
            <label>{{ $t(builderModulePartFieldDeclaration.title) }}</label>
            <p class="mb-0 text-danger small font-weight-bolder">{{ $t('Invalid') }} - {{ $t('Please contact support') }}</p>
            <p class="mb-0 text-warning small font-weight-normal">{{ $t(builderModulePartFieldDeclaration.title) }} / {{ builderModulePartFieldDeclaration.type }} / {{ builderModulePartFieldDeclaration.specificType }}</p>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import RandomUtils from '@/common/utils/random.utils';
import EtbModuleCustomizerControlFileImage from './EtbModuleCustomizerControlFileImage';
import EtbModuleCustomizerControlRichText from './EtbModuleCustomizerControlRichText';

const BUILDER_MODULE_DATA_DATA_WATCHER_DEBOUNCE = 100;

/**
 * Module Customizer Control.
 *
 * @author Dimitris Gkoulis
 * @createdAt 27 October 2020
 * @lastModifiedAt 9 November 2020 (translations)
 */
export default {
    name: 'EtbModuleCustomizerControl',
    components: {
        EtbModuleCustomizerControlFileImage,
        EtbModuleCustomizerControlRichText
    },
    props: {
        builderModulePartFieldDeclaration: {
            type: Object,
            default: null,
            required: true
        },
        builderModuleDataDataValue: {
            type: null,
            required: true
        }
    },
    data () {
        return {
            elementIdPrefix: null, // Required because it's possible to have duplicate element IDs (e.g. group).
            siInitialized: false,
            labelHelpText: null,
            dataValue: null
        };
    },
    beforeMount () {
        this.elementIdPrefix = RandomUtils.getUniqueId();
        this.dataValue = cloneDeep(this.builderModuleDataDataValue);

        if (this.builderModulePartFieldDeclaration.type === 'spacing') {
            switch (this.builderModulePartFieldDeclaration.specificType) {
            case 'full':
                this.labelHelpText = 'Top Bottom Left Right';
                break;
            case 'topbottom':
                this.labelHelpText = 'Top Bottom';
                break;
            case 'leftright':
                this.labelHelpText = 'Left Right';
                break;
            case 'x':
                this.labelHelpText = 'Left & Right';
                break;
            case 'y':
                this.labelHelpText = 'Top & Bottom';
                break;
            case 'xy':
                this.labelHelpText = 'Top & Bottom / Left & Right';
                break;
            case 'top':
                this.labelHelpText = 'Top';
                break;
            case 'right':
                this.labelHelpText = 'Right';
                break;
            case 'bottom':
                this.labelHelpText = 'Bottom';
                break;
            case 'left':
                this.labelHelpText = 'Left';
                break;
            default:
                this.labelHelpText = null;
                break;
            }
        }

        // Listen to changes on 'builderModuleDataData'.
        // On each change send the whole builderModuleData with the new 'data' field.
        this.$watch('dataValue', {
            handler: debounce(function (ignoredValue) {
                this.$emit('change', {
                    name: this.builderModulePartFieldDeclaration.name,
                    builderModuleDataDataValue: cloneDeep(this.dataValue)
                });
            }, BUILDER_MODULE_DATA_DATA_WATCHER_DEBOUNCE),
            deep: true,
            immediate: false
        });
    },
    methods: {
        /**
         * Handles EtbModuleCustomizerControlFileImage's signal for change.
         */
        handleEtbModuleCustomizerControlFileImageChange ($event) {
            this.dataValue = $event;
        },

        /**
         * Handles EtbModuleCustomizerControlRichText's signal for change.
         */
        handleEtbModuleCustomizerControlRichTextChange ($event) {
            this.dataValue = $event;
        },

        getUniqueElementId (identifier) {
            return this.elementIdPrefix + '_' + identifier;
        }
    }
};
</script>

<style lang="scss">
    .EtbModuleCustomizerControl {

        .el-select,
        .el-color-picker,
        .el-color-picker__trigger,
        .el-input-number {
            width: 100%;
        }

        .el-form-group--etb--spacing {

            .el-input-number.is-controls-right .el-input__inner {
                padding-left: 10px;
                padding-right: 40px;
            }
        }
    }
</style>
