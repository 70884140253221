/****************************************************************************************************
 * BuilderModuleData model.
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

import RandomUtils from '@/common/utils/random.utils';

const Model = {
    /**
     * Unique identifier of ModuleData instance.
     *
     * This is used for persistence, rendering, and comparison (not for business logic).
     */
    key: null,

    /**
     * Name of module (which also declares its type).
     */
    name: null,

    /**
     * Version of module.
     */
    version: 1,

    /**
     * Part statuses key-value pairs.
     *
     * Key is name of pair.
     * Value is true or false.
     */
    partStatuses: {},

    /**
     * User input.
     *
     * Can be anything.
     */
    data: null
};

export default {
    getRaw () {
        return cloneDeep(Model);
    },
    getNew (name, data, version = 1, partStatuses = {}) {
        if (typeof name !== 'string') throw new Error('name must be a string!');
        if (typeof version !== 'number') version = 1;
        if (data === undefined) data = null;

        const model = cloneDeep(Model);
        model.key = RandomUtils.getUniqueId(name);
        model.name = name;
        model.version = version;
        model.partStatuses = partStatuses;
        model.data = data;
        return model;
    }
};
