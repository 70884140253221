/****************************************************************************************************
 * MJML Submodule repository.
 *
 * @author Dimitris Gkoulis
 * @createdAt 29 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

import NavbarLinkSubmodule from '../mjml-submodule/navbar-link-summodule.model';

const MjmlSubmoduleMap = {
    [NavbarLinkSubmodule.getName()]: NavbarLinkSubmodule.getRaw()
};

export default {
    /**
     * Return a deep clone of MjmlSubmoduleMap map.
     */
    getMjmlSubmoduleMap () {
        return cloneDeep(MjmlSubmoduleMap);
    },

    /**
     * Get the "name" MJML Submodule or null if does not exist.
     */
    getMjmlSubmoduleByName (name) {
        if (typeof name !== 'string') return null;
        if (!MjmlSubmoduleMap.hasOwnProperty(name)) return null;
        return cloneDeep(MjmlSubmoduleMap[name]);
    },

    /**
     * Check if the "name" MJML Submodule exists.
     */
    doesMjmlSubmoduleExists (name) {
        if (typeof name !== 'string') return false;
        return MjmlSubmoduleMap.hasOwnProperty(name);
    }
};
