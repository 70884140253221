/****************************************************************************************************
 * Navbar Link MJML submodule.
 *
 * See https://mjml.io/documentation/#mj-navbar-link
 *
 * @author Dimitris Gkoulis
 * @createdAt 29 October 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const Model = {
    name: 'navbar-link',
    version: 1,
    // MJML //////////
    tagName: 'mj-navbar-link',
    attributes: {
        'color': '#ffffff',
        // css-class
        // font-family
        'font-size': '14px',
        // font-style
        'font-weight': 500, // Number only
        'href': '#',
        // line-height // In pixels
        'padding': '0 10px 0 10px',
        // padding-bottom
        // padding-left
        // padding-right
        // padding-top
        // rel
        'target': '_blank',
        // text-decoration
        'text-transform': 'none'
    },
    content: 'Link'
};

export default {
    getRaw () {
        return cloneDeep(Model);
    },
    getName () {
        return Model.name;
    }
};
