/****************************************************************************************************
 * MJML Service for managing conversions and transformations of builder data to MJML (XML, JSON, HTML).
 *
 * @author Dimitris Gkoulis
 * @createdAt 24 October 2020
 * @lastModifiedAt 7 November 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';

import LoggingService from '@/common/services/logging.service';

// Models.
import BuilderDataModel from './builder-data/builder-data.model';
import BuilderModuleDataModel from './builder-data/builder-module-data.model';

// MJML built-in Components.
import MjmlComponentHelper from './mjml/mjml-component-helper';

// MJML custom Modules (repository).
import BuilderModuleDeclarationRepository from './repository/builder-module-declaration.repository';
import MjmlModuleRepository from './repository/mjml-module.repository';
import MjmlSubmoduleRepository from './repository/mjml-submodule.repository';

/**
 * Given a parameter, checks if its number.
 * If it's number it constructs and returns the 'Spacing' MJML.
 */
function getSpacingFromSettingsByUncheckedDataNumber (data) {
    if (typeof data !== 'number') return null;
    if (data <= 0) return null;
    const spacingTopModule = MjmlModuleRepository.getMjmlModuleByName('spacing');
    spacingTopModule['children'][0]['children'][0]['attributes']['height'] = data + 'px';
    spacingTopModule['attributes']['background-color'] = 'transparent';
    return spacingTopModule;
}

/**
 * Constructs BuilderData instance with default values for a specific BuilderModuleDeclaration.
 *
 * @param builderModuleDeclaration the BuilderModuleDeclaration to construct BuilderData for.
 */
function constructBuilderDataForBuilderModuleDeclaration (builderModuleDeclaration) {
    const actualData = {};
    const partStatuses = {};

    // for each field on each part get name and default value and assemble the actual (initial) data of module.
    for (const part of builderModuleDeclaration.parts) {
        partStatuses[part.name] = true;
        for (const field of part.fields) {
            if (field.type === 'group') {
                // If default value is empty, add one item with the defaults.
                if (field.defaultValue.length > 0) {
                    actualData[field.name] = field.defaultValue;
                } else {
                    const actualDataListItem = {};
                    for (const childField of field.children) {
                        if (childField.type === 'group') continue; // Nested groups in groups do not allowed!
                        // @future: OKAY, but do we have to set a value? Think about it and act as required.
                        actualDataListItem[childField.name] = childField.defaultValue;
                    }
                    actualData[field.name] = [];
                    actualData[field.name].push(actualDataListItem);
                }
            } else {
                actualData[field.name] = field.defaultValue; // no need to clone deeply (we return deep clone)
            }
        }
    }

    return {
        actualData: actualData,
        partStatuses: partStatuses
    };
}

const EmailTemplateBuilderService = {};

/**
 * Get the BuilderDeclarationMap map.
 */
EmailTemplateBuilderService.getBuilderDeclarationMap = function () {
    return BuilderModuleDeclarationRepository.getBuilderDeclarationMap();
};

/**
 * Get the list that contains BuilderModuleDeclaration instances grouped by category.
 */
EmailTemplateBuilderService.getBuilderModuleDeclarationsByCategoryList = function () {
    return BuilderModuleDeclarationRepository.getBuilderDeclarationsByCategoryList();
};

/**
 * Constructs BuilderData with default values.
 */
EmailTemplateBuilderService.constructBuilderData = function () {
    const builderSettingsDeclaration = BuilderModuleDeclarationRepository.getBuilderSettingsDeclaration();

    const result = constructBuilderDataForBuilderModuleDeclaration(builderSettingsDeclaration);
    const actualData = result.actualData;
    const partStatuses = result.partStatuses;

    const builderModuleDataForSettings = BuilderModuleDataModel.getNew('settings', actualData, 1, partStatuses);
    const builderData = BuilderDataModel.getRaw();

    builderData.settings = builderModuleDataForSettings;
    builderData.moduleDataList = [];

    return cloneDeep(builderData);
};

/**
 * Provides a convenient way to construct a new BuilderModuleData instance
 * based on the "name" BuilderModuleDeclaration.
 *
 * @param name the "name" BuilderModuleDeclaration.
 */
EmailTemplateBuilderService.constructBuilderModuleDataByName = function (name) {
    if (typeof name !== 'string') return null;
    if (!BuilderModuleDeclarationRepository.doesBuilderModuleDeclarationExists(name)) return null;

    const builderModuleDeclaration = BuilderModuleDeclarationRepository.getBuilderModuleDeclarationByName(name);

    const result = constructBuilderDataForBuilderModuleDeclaration(builderModuleDeclaration);
    const actualData = result.actualData;
    const partStatuses = result.partStatuses;

    const builderModuleData = BuilderModuleDataModel.getNew(name, actualData, 1, partStatuses);
    return cloneDeep(builderModuleData);
};

/**
 * Transforms BuilderData to MJML JSON Object.
 *
 * @param builderData the BuilderData to transform.
 */
EmailTemplateBuilderService.transformBuilderDataToMjmlJsonObject = function (builderData) {
    // Initialize MJML tree.
    const root = MjmlComponentHelper.getRoot();

    // Settings and globals. //////////
    const builderSettingsDeclaration = BuilderModuleDeclarationRepository.getBuilderSettingsDeclaration();
    const builderSettingsResolvedData = {};
    const builderSettingsOriginalData = {}; // Unprocessed data for special processing.
    for (const part of builderSettingsDeclaration.parts) {
        for (const field of part.fields) {
            const data = builderData.settings.data[field.name];
            // 'resolveData': We assume that always return a valid value (clean, validate, correct).
            builderSettingsResolvedData[field.name] = field.resolveData(data); // Value for MJML.
            builderSettingsOriginalData[field.name] = data;
        }
    }

    root['children'][1]['attributes']['background-color'] = builderSettingsResolvedData['bodyBackgroundColor'];
    root['children'][1]['attributes']['width'] = builderSettingsResolvedData['bodyWidth'];

    let mjmlFontComponent = MjmlComponentHelper.getFontByName(builderSettingsResolvedData['bodyFont']);
    if (mjmlFontComponent === null || mjmlFontComponent === undefined) {
        LoggingService.warn('mjmlFontComponent is missing. Setting default font.');
        mjmlFontComponent = MjmlComponentHelper.getDefaultFont();
    }
    root['children'][0]['children'][1] = mjmlFontComponent;
    root['children'][0]['children'][2]['children'][0]['attributes']['font-family'] = mjmlFontComponent.fontFamily;

    // Modules and components. //////////
    const mjmlModuleList = [];

    // Spacing top.
    const spacingTopModule = getSpacingFromSettingsByUncheckedDataNumber(get(builderSettingsOriginalData, 'bodyTopBottomSpacing.top', null));
    if (spacingTopModule !== null) {
        spacingTopModule['attributes']['css-class'] = null;
        mjmlModuleList.push(spacingTopModule);
    }

    for (const builderModuleData of builderData.moduleDataList) {
        if (builderModuleData == null) {
            LoggingService.warn('builderModuleData is null!');
            continue;
        }
        if (typeof builderModuleData !== 'object') {
            LoggingService.warn('builderModuleData is not object!');
            continue;
        }

        // Get BuilderModuleDeclaration and MJML Module by name.
        const name = builderModuleData.name;

        // @future Check for custom resolver, provide builderModuleData, get MJML, add to list, and finally CONTINUE.

        if (!BuilderModuleDeclarationRepository.doesBuilderModuleDeclarationExists(name)) {
            LoggingService.warn(`BuilderModuleDeclaration ${name} does not exist!`);
            continue;
        }
        const builderModuleDeclaration = BuilderModuleDeclarationRepository.getBuilderModuleDeclarationByName(name);

        const pointsTo = builderModuleDeclaration.pointsTo;

        if (typeof pointsTo !== 'string') {
            LoggingService.warn(`BuilderModuleDeclaration ${name} pointsTo is not string!`);
            continue;
        }

        if (!MjmlModuleRepository.doesMjmlModuleExists(pointsTo)) {
            LoggingService.warn(`MJML Module ${pointsTo} does not exist!`);
            continue;
        }
        const mjmlModule = MjmlModuleRepository.getMjmlModuleByName(pointsTo);

        // For each field on each part get name and default value and assemble the actual (initial) data of module.
        for (const part of builderModuleDeclaration.parts) {
            // @future Now is the time to decide if the part must be displayed/enabled.

            for (const field of part.fields) {
                if (field.type === 'group') {
                    if (!MjmlSubmoduleRepository.doesMjmlSubmoduleExists(field.pointsTo)) {
                        LoggingService.warn(`MJML Submodule ${field.pointsTo} does not exist!`);
                        continue;
                    }
                    const mjmlSubmodule = MjmlSubmoduleRepository.getMjmlSubmoduleByName(field.pointsTo);
                    const mjmlSubmoduleList = [];

                    const childFieldMap = field.children
                        .reduce(function (accumulator, current) {
                            accumulator[current.name] = current;
                            return accumulator;
                        }, {});

                    if (!Array.isArray(builderModuleData.data[field.name])) {
                        LoggingService.warn(`builderModuleData.data ${field.name} is not an array!`);
                        continue;
                    }

                    for (const dataItem of builderModuleData.data[field.name]) {
                        const mjmlSubmoduleClone = cloneDeep(mjmlSubmodule);

                        for (const dataItemField of Object.keys(dataItem)) {
                            const childField = childFieldMap[dataItemField];
                            if (childField === null || childField === undefined) {
                                LoggingService.warn(`Child Field ${dataItemField} does not exist!`);
                                continue;
                            }

                            const mjmlPath = childField.mjmlPath;
                            if (mjmlPath === null || mjmlPath === undefined) {
                                // @future Special processing required - NOT YET IMPLEMENTED.
                            } else {
                                // 'resolveData': We assume that always return a valid value (clean, validate, correct).
                                const valueForMjmlSubmodule = childField.resolveData(dataItem[dataItemField]);
                                set(mjmlSubmoduleClone, mjmlPath, valueForMjmlSubmodule);
                            }
                        }

                        mjmlSubmoduleList.push(mjmlSubmoduleClone);
                    }

                    set(mjmlModule, field.mjmlPath, mjmlSubmoduleList);
                } else {
                    const mjmlPath = field.mjmlPath;
                    if (mjmlPath === null || mjmlPath === undefined) {
                        // @future Special processing required - NOT YET IMPLEMENTED.
                    } else {
                        const data = builderModuleData.data[field.name];
                        // 'resolveData': We assume that always return a valid value (clean, validate, correct).
                        const valueForMjmlModule = field.resolveData(data);
                        set(mjmlModule, mjmlPath, valueForMjmlModule);
                    }
                }
            }
        }

        // Add class and identifying class - REQUIRED BY EMAIL TEMPLATE BUILDER (JS).
        mjmlModule['attributes']['css-class'] = 'ClEtbModule ' + 'ClEtbModule__' + builderModuleData.key;

        mjmlModuleList.push(mjmlModule);
    }

    // Spacing bottom.
    const spacingBottomModule = getSpacingFromSettingsByUncheckedDataNumber(get(builderSettingsOriginalData, 'bodyTopBottomSpacing.bottom', null));
    if (spacingBottomModule !== null) {
        spacingBottomModule['attributes']['css-class'] = null;
        mjmlModuleList.push(spacingBottomModule);
    }

    root['children'][1]['children'] = mjmlModuleList;

    return root;
};

export default EmailTemplateBuilderService;
